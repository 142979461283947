import React from "react";

export default function Mediation() {
  return (
    <>
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{
          backgroundImage:
            "url('/assets/images/360_F_370522336_5xLA2lQINrlPhzZ1kLl5uSgVOoiyd8AY.jpg')",
        }}
        data-aos="fade"
        data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-8 text-center"
              data-aos="fade-up"
              data-aos-delay="400">
              <h1 className="mb-4">Mediation</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-lg-0 mb-5 d-flex align-items-center">
              <img
                src="/assets/images/icdrlogo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 pl-lg-5">
              Mediation is a structured, interactive process where an impartial
              third party neutrally assists disputing parties in resolving
              conflict through the use of specialized communication and
              negotiation techniques. All participants in mediation are
              encouraged to actively participate in the process. Mediation is a
              "party-centered" process in that it is focused primarily upon the
              needs, rights, and interests of the parties. The mediator uses a
              wide variety of techniques to guide the process in a constructive
              direction and to help the parties find their optimal solution. A
              mediator is facilitative in that they manage the interaction
              between parties and facilitates open communication. Mediation is
              also evaluative in that the mediator analyzes issues and relevant
              norms, while refraining from providing prescriptive advice to the
              parties .
              <br />
              Mediation, is a form of alternative dispute resolution resolving
              disputes between two or more parties with concrete effects.
              Typically, a third party, the mediator, assists the parties to
              negotiate a settlement. Disputants may mediate disputes in a
              variety of domains, such as commercial, legal, diplomatic,
              workplace, community, and family matters.
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-lg-0 mb-5 order-lg-2 d-flex align-items-center">
              <img src="/images/img_1.jpg" alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 pr-lg-5">
              The term mediation broadly refers to any instance in which a third
              party helps others reach an agreement. More specifically,
              mediation has a structure, timetable, and dynamics that "ordinary"
              negotiation lacks. The process is private and confidential. The
              mediator acts as a neutral third party and facilitates rather than
              directs the process. Mediation is becoming a more peaceful and
              internationally accepted solution to end the conflict. Mediation
              can be used to resolve disputes of any magnitude.
              <br />
              Mediators use various techniques to open, or improve, dialogue and
              empathy between disputants, aiming to help the parties reach an
              agreement. Much depends on the mediator's skill and training. As
              the practice gained popularity, training programs, certifications,
              and licensing followed, which produced trained and professional
              mediators committed to the discipline.
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section-2">
        <div className="container">
          <div className="row no-gutters align-items-stretch align-items-center">
            <div className="col-lg-4">
              <div
                className="service-1 first h-100"
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-balance"></span>
                  </span>
                  <h2>Business Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_2.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-law"></span>
                  </span>
                  <h2>Criminal Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_3.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-courthouse"></span>
                  </span>
                  <h2>Tax Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="service-1 first h-100"
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-balance"></span>
                  </span>
                  <h2>Business Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_2.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-law"></span>
                  </span>
                  <h2>Criminal Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_3.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-courthouse"></span>
                  </span>
                  <h2>Tax Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
