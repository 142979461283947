import React from "react";
import { connect } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { fetchApi } from "../../Utils/Connections";

const mapStateToProps = (state: any) => {
  return {
    modal: state.modal,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setModal: (modal: boolean) =>
      dispatch({ type: "SET_MODAL", payload: modal }),
  };
};

const MemberModal = ({ modal, setModal }: any) => {
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [member, setMember] = React.useState<any>(null);

  const [code, setCode] = React.useState("");

  const checkCode = async () => {
    setLoading(true);
    const result = await fetchApi(`Public/GetUserByCode?Code=${code}`, "GET", {
      code: "1234",
    });
    console.log("result", result);
    if (result.StatusCode === 200) {
      setValid(true);
      setMember(result.My_Result);
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={`backdrop ${modal && "active"}`}
        onClick={() => {
          setModal(false);
          setValid(false);
          setMember(null);
        }}></div>
      <div className={`icdr-modal ${modal && "active"} `}>
        {!valid && (
          <div className="p-3">
            <h3>Enter your code</h3>
            <input
              type="text"
              className="form-control rounded"
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
            />
            {loading && (
              <div className="d-flex justify-content-center mb-5">
                {" "}
                <Spinner />
              </div>
            )}
            <button
              className="btn btn-primary btn-block my-2"
              onClick={checkCode}
              disabled={loading}>
              Submit
            </button>
          </div>
        )}
        {valid && (
          <div className="p-3">
            <h3>Member Info</h3>
            <i
              className="bi bi-x close"
              onClick={() => {
                setModal(false);
                setValid(false);
                setMember(null);
              }}></i>
            <hr />
            <div className="text-center">
              {/* <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt="Avatar"
                width={100}
                height={100}
                className="avatar rounded-circle"
              /> */}
              <h4>{member.FullName}</h4>
              <div>{member.Position}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberModal);
