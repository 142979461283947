import React from "react";
import { Collapse } from "react-bootstrap";

export default function AboutTheCourt() {
  const [open, setOpen] = React.useState<boolean[]>([]);

  const openCollapse = (index: number) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };

  return (
    <>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(0)}>
          <h3>
            About The <span className="text-primary">Court</span>
          </h3>
          <i className={`bi bi-chevron-${open[0] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[0]}>
          <div>
            <p>
              <strong>
                The International Supreme Court for Dispute Resolution
              </strong>{" "}
              is an International entity headquarter in{" "}
              <strong>London-United Kingdom </strong>
              registered under the numbers{" "}
              <strong>
                APO-1240622 and APO-11738311 and APO-13566880
              </strong>. <br />
              The international supreme court for dispute resolution ,a private
              arbitral Court is engaged in the peaceful and amicable settlement
              of disputes through arbitration, mediation, conciliation
              diplomacy, peace, and alternative dispute resolution methods. The
              Court has a standing committee to formulate laws and enact model
              International laws. The International Supreme Court For Dispute
              Resolution works at the international and national levels and
              provide a quick and fair settlement that il help economic growth,
              stimulate international investment and international trade. It is
              the most important goal in the world today. If the judicial
              authorities are the body empowered by a constitution and a law to
              settle persons, it is not necessary to resolve all cases. Other
              means are used to resolve and settle disputes between the parties
              to the conflict themselves. Since an ancient times, human society
              has known peaceful and friendly means to resolve disputes such as
              arbitration, mediation, negotiation and reconciliation.
              Thesemethods have been referred, to in International jurisprudence
              as an alternative means of settling disputes.
              <br /> It is noteworthy that the Court has a number of members
              experts, arbitrators and judges.
            </p>
            <p>
              <strong>
                The lists of the court's dispute resolution judges are divided
                into three categories:
              </strong>
              <ul>
                <li>Category (A)</li>
                <li>Category (B)</li>
                <li>Category (C)</li>
              </ul>
              <br />
              <h4>Advantages of the Membership of the court:</h4>
              <ul>
                <li>
                  The possibility of appointing members of the settlements to
                  which the Court participate.
                </li>
                <li>
                  Participate in the development and drafting of model laws
                  issued by the law of the court.
                </li>
                <li>
                  Membership shows the role and the experience of the arbitrator
                  and mediator behind clients.
                </li>
                <li>
                  Opportunity to choose a member as an international arbitrator
                  or mediator in disputes and its adoption within the
                  international federations.
                </li>
                <li>
                  Membership also provides an opportunity to become a judge
                  accredited in court.
                </li>
              </ul>
              <br />
              <h4>About arbitration:</h4> Article 37 of Hague convention in 1970
              provide that the International arbitration is aimed at settling
              disputes, between states through judges elected on the basis of
              respect for the rightand that recourse to arbitration produced on
              the basis of good faith. <br />
              Arbitration is the agreement between two or more parties to resort
              to arbitration to settle all or some disputes, may of which
              because of the existence of a certain legal relationship.
              <br />
              Arbitration is a special jurisdiction that is incorporated in all
              fields of specializations (accountants, lawyers , counselors,
              engineers. journalists, doctors, security officers, business men,
              investors, tax officer........). The procedure rules of the
              International Supreme Court For Dispute Resolution between the
              objectives andthe implementation.
            </p>
            <p>
              <h4>The objectives of the court:</h4>
              <ul>
                <li>
                  Resolving disputes arising from contracts of trade and
                  investment contracts nationally and internationally.
                </li>
                <li>
                  Attract businessman and companies to invest and evade recourse
                  to the local judiciary i solving their contractual disputes.
                </li>
                <li>
                  Developing arbitration knowledge among individuals, companies
                  and institutions for the importance of arbitration in
                  resolving disputes arising from commercial and investment
                  contracts minimal efforts and shortest procedures.
                </li>
                <li>
                  Supporting and developing the process of justice through
                  arbitration.
                </li>
              </ul>
              <h4>The Court proceedings:</h4>
              The court shall deal with arbitration nnd and settlement
              settlement of of disputes disputes in accordance with the rules of
              Uncitral or any rules or procedures chosen by the parties to the
              disputes
              <h4>By:</h4>
              <ul>
                <li>Arbitration in Artificial Intelligence</li>
                <li>Arbitration in financial construction contracts</li>
                <li>Arbitration in investment contracts and disputes</li>
                <li>Arbitration in state contracts.</li>
                <li>Arbitration in contracts of individuals</li>
                <li>Arbitration in insurance contracts.</li>
                Arbitration in oil contracts
                <li>Arbitration in gas contracts</li>
                <li>
                  Arbitration in contracts of telecommunications companies
                </li>
                <li>Arbitration in international contracts</li>
                <li>Arbitration in franchise contracts</li>
                <li>Arbitration in maritime disputes.</li>
                <li>Arbitration in workshop.</li>
                <li>Arbitration in bank contracts.</li>
                <li>Electronic arbitration and information security.</li>
                <li>Arbitration in intellectual property.</li>
                <li>Engineers arbitration.</li>
                <li>Arbitration of air disputes aviation contacts</li>
                <li>Arbitration in banking disputes</li>
                <li>International commercial Arbitration.</li>
                <li>Compulsory Arbitration.</li>
                <li>
                  Arbitration by the law and arbitration in reconciliation.
                </li>
                <li>Arbitration in accordance with justice and equity.</li>
                <li>Emergency arbitration.</li>
                <li>Environmental arbitration</li>
                <li>Arbitration in sports disputes.</li>
                <li>Adhok arbitration.</li>
                <li>Diplomatic relations</li>
                <li>Arbitration agreement</li>
                <li>Arbitration procedures between theory and practice.</li>
                <li>The invalidity of the arbitration award</li>
                <li>
                  Implementation of national and international arbitration
                </li>
                <li>Formulations of contracts in Arabic and English.</li>
                <li>Banking contracts and intellectual propriety.</li>
                And all contracts that include the arbitration clause, the
                arbitration clause and the assignment of arbitration.
              </ul>
            </p>
            <p>
              <h4>Model arbitration Condition:</h4>
              The International Supreme Court For Dispute Resolution London- UK
              recommend s that parties whishing to have recourse to arbitration
              include in their contracts and agreements the model arbitration
              clause.
              <br /> Any dispute arising in connection with contract, both with
              regard to its implementation or annulment or dissolution or
              termination or interpretation of this contract. Or termination of
              this contract or related to the subject matter of the contract in
              any form was resolved permanently by arbitration and in accordance
              with the rules and procedures of the International Supreme Court
              For Dispute Resolution by An arbitrator Or Arbitral Court
              <br />
              <h4>Starting Arbitration:</h4> Each party whishing to proceed
              shall be governed by the rules and procedures of the International
              Supreme Court For Dispute Resolution, the plaintiff initiates the
              action against the defendant and sends to the International
              supreme court for dispute resolution a written request for
              arbitration of the claim attached to the request to refer the
              dispute including the notice of arbitration and the arbitral
              proceedings shall be deemed to have commenced on the date of
              receipt of the arbitration notice by the court.
              <ul>
                <li>
                  The application shall include the names, adresses, phone
                  numbers, fax, e-mails of the parties to the dispute, lawyers
                  or their legal representatives.
                </li>
                <li>
                  A opy of the arbitration clause or the ritten arbitration
                  agreement made by the plaintiff and the arbitration contract.
                </li>
                <li>
                  A copy of the contractual documents containing the arbitration
                  clause or under which the arbitration was established.
                </li>
                <p>
                  A description of the nature and circumstances f the dispute
                  including specifically the plaintiff's claims against the
                  defendant in the arbitration.
                </p>
                <p>
                  The number arbitration, their qualifications and their of
                  nationalities, which the parties have already agreed upon in
                  writing, in respect of the arbitration or for which the
                  applicant is willing to submit proposals.
                </p>
                <li>
                  The applcation shall include the name , address, phone
                  numbers, faxes, e-mails and charges stated in the expense
                  table of the proposed arbitrator includes in the request for
                  arbitration the prescribed
                </li>
                <li>
                  Also registration fee, which has been agreed upon and in the
                  case of nonpayment, the application shall be preserved without
                  prejudice to the right to submit a new application.
                </li>
                <li>
                  It shall also confirm in the request to the court that copies
                  of it have been communicated to other parties by means to be
                  mentioned in this confirmation.
                </li>
                <li>
                  The date of submission of documents to the Court for the
                  application of arbitration shall be considered as the date of
                  the commencement of arbitration of al I subjects containing
                  all the documents attached to the Court in two copies, in
                  which an individual arbitrator should be appointed or if the
                  parties that three arbitrators of four copies.
                </li>
              </ul>
            </p>
            <p>
              <h4>Respondent's answer:</h4> The defendant must send to the court
              a written response to the claim within thirty days:
              <ul>
                <li>
                  The approve, partial approval or rejection of the claimant's
                  claims.
                </li>
                <li>
                  A brief description of the nature and circumstances of any
                  counter claims made by the defendant in the face of the
                  allegation.
                </li>
                <li>
                  Commenting as an answer to shall statements received regarding
                  the conduct of the arbitration.
                </li>
                <li>
                  If the arbitral contract provides that the parties shall name
                  the arbitrators, the answer of the claimant shall include the
                  name of the arbitrator appointed if known (fax, phone number,
                  telex, e-mail).
                </li>
                <li>
                  Requires that the court be assured that copies of its reply
                  have been sent to the rest of the parties.
                </li>
                <li>
                  Two copies of the answer must be submitted or if the parties
                  agree that three arbitrators should be appointed to four
                  copies.
                </li>
                <li>
                  If the defendant fails to answer, this does not prevent him
                  from making any counter claim in his However, if the arbitral
                  contract provides that the parties appoint defensive
                  memorandum. the arbitrator, efraining from sending the answer
                  even if the arbitrator is named constitutes a waiver of this
                  party has the right to name the arbitrator.
                </li>
              </ul>
              The International Supreme Court For Dispute Resolutionshall
              exercise its system in the name of the president, the vice
              president, or a section of its members and advisers composed of
              three, five, even or nine members of the court on the instructions
              of the president. -The function of the clerk or his deputy shall
              be exercised under the supervision and instructions of the
              International Supreme Court for Dispute Resolution.
            </p>
            <p>
              <h4>Duration of the arbitration award:</h4> Prior to the
              commencement of the arbitral proceedings one of the parties shall
              have the right to appoint an emergency arbitrator, before sending
              the file to the arbitral court, including the request for urgent
              measures. The emergency arbitrator shall be appointed by the
              president within three days after the request for urgent measures.
              <ul>
                <li>
                  The arbitral court shall be informed immediately uapon the
                  appointment of the emergency arbitrator, a copy to the
                  chairman of the arbitral court.
                </li>
                <li>
                  The emergency arbitrator must be impartial and neutral and
                  independent of the parties to the conflict.
                </li>
                <li>
                  The emergency arbitrator shall sign before his appointment a
                  declaration of acceptance, impartiality and independence.
                </li>
                <li>
                  In his turn, the arbitrator shall submit a copy of the
                  declaration signed by himself to the arbitral court and the
                  parties to the dispute.
                </li>
                <li>
                  An emergency arbitrator can not be in the arbitral court in a
                  case where the expedited measures have been suspended except
                  with the consent of the parties.
                </li>
                <li>
                  The emergency arbitrator shall request the schedule to study
                  the expedited measure and shall not exceed three days. It
                  shall also have a reasonable opportunity to hear from all
                  parties. t may hold a meeting by phone or call bpy voice,
                  image, written regulations or any other means as an
                  alternative to the official hearing.
                </li>
                <li>
                  The emergency arbitrator shall enjoy the same power as the
                  arbitral court in terms of considering and resolving the
                  dispute.
                </li>
                <li>
                  The emergency arbitrator shall also have the power to issue or
                  order any precautionary measures he may deem necessary,
                  including temporary and precautionary measures to protect or
                  preserve property. Such measure shall e an order or a
                  provisional decision.
                </li>
                <li>
                  The emergency court shall ave the right to refer to the
                  measures or orders it has taken.
                </li>
                <li>
                  Any provision or interim arbitral award shall have the same
                  effect as the arbitral measures and shall be binding on the
                  parties when it is isued and shall be subject to provisional
                  judgment without delay.
                </li>
                <li>
                  The terms of the emergency arbitrator shall terminate the
                  formation of the arbitral court, which upon its formation,
                  shall re- examine, amend or nullify the interim arbitration
                  award relating to the expedited procedure.
                </li>
                <li>
                  The requesting party for urgent measure shall provide the
                  necessary security
                </li>
                <li>
                  The request for an interim measure submitted to any judicial
                  authority of a party shall not be considered as a viewer of
                  the right of recourse to arbitration.
                </li>
                <li>
                  Costs relating to requests for expedited proceedings by an
                  arbitrator. The arbitral court shall have the power to
                  determine such costs in a timely manner.
                </li>
                The court may form a committee or several committee and
                determine its functions and organization.
                <br /> The court also operates conferences and symposia related
                to arbitration in a manner that contributes to the provision of
                successive generations of arbitrators and their continues
                improvement.
                <br /> The court shall open the doors of education, training,
                and membership of all specialties of judges, arbitrators,
                lawyers, expert, academics and bankers....
                <li>
                  The aim shall be education in all ewW and exchange of
                  information and experience throughout t he world in mediation
                  conciliation, diplomatic solutions and peace. Arbitration and
                  settlement of disputes by peaceful means.
                </li>
                <li>
                  Provide flexible and impartial mechanisms to resolve dispute
                  settlement easily and conveniently and companies to the body
                  of their disputes with the International Supreme Court For
                  Dispute Resolution.
                </li>
                <li>
                  Provide the parties with the means to solve their disputes
                  through arbitration or its modern alternatives thought the
                  International Supreme Court for Dispute Resolution.
                </li>
                <li>
                  -Issuing periodicals and publications related to the activity
                  of the court.
                </li>
                <li>
                  Canting certificates to participants in any activity of the
                  International supreme court for dispute resolution.
                </li>
              </ul>
            </p>
            <p>
              <h4>Privileges of the court and judges:</h4>
              Immunity of the international supreme court for dispute resolution
              of justice and the privileges and immunity ofthe arbitrators
              therein: <br />
              an "Headquarters" means the area or any building, building,
              including conference facilities, parts of buildings, and o
              facilities, regardless of ownership, hich the international
              supreme court for dispute resolution uses permanently or from time
              to time to carry out its official functions.
            </p>
            <p>
              <h4>Court proceedings:</h4>
              Dispute resolution, the administration of the international office
              of the court based on the united nations UNCITRAI model law with
              all the comprehensive adjustments that have adopted year 2006 and
              2010 and 2013 and UNCITRAI model law on electronic commerce with
              the guide enactment of 1996 with the additional article 5 adopted
              in 1998 and all amendments to the court also adopts the Mauritius
              International Arbitration Act.
              <ul>
                <li>
                  -"Arbitrator in the court" means the arbitrator or mediator or
                  member of the truth committee which is involved in a meeting
                  or other activities included the court proceedings.
                </li>
                <li>
                  -"Participant in proceedings "means the witness, expert,
                  lawyer, party to the dispute, agent or any representative of
                  the party who participates in a meeting or other activities of
                  the proceedings of the court.
                </li>
                <li>
                  -"The meeting of the court means any meeting of any of the
                  organs of the court or its subsidiary organs or any internal
                  conference or any gatherings thereof or under its supervision
                </li>
                <li>
                  -"President of the Court" means t he president of the
                  international office of the court.
                </li>
              </ul>
              The court and its property shall enjoy, wherever the owner of the
              property is, immunity by all legal procedures.
              <br />
              The concerned authorities shall exercise due diligence to ensure
              that the security and safety of the headquarters are not targeted
              by any persons attempting t enters t he premises i n an
              unauthorities manner or to carry out riots in the vicinilg thereof
              To do this, the competent authorities shall provide the necessary
              security protection on the walls of the premises, proximity to it.
              At the request of the president of the court, the competent
              authorities shall send an adequate number of security forces to
              maintain law, order and premises.
              <br />
              Immunity of the arbitrators in the international supreme court for
              dispute resolution and the participants in the proceedings:
              <br />
              During the performance of their duties, t he arbitrators ofthe
              court shall enjoy immunities which shall accord to the immunities
              of judges or diplomats in accordance with the Vienna Convention.
              <br />
              The participants in the proceedings shall enjoy immunity from
              juridical ,administrative, civil and criminal jurisdiction for the
              acts they performed in the performance of their duties in the
              proceedings of the Court.
              <br />
              The president of the court shall enjoy the privileges, immunities,
              exemptions and facilities enjoyed by the head of the Diplomatic
              Mission in accordance with the Vienna Convention.
              <br />
              Except for the president, the officials of the court shall enjoy
              the same privileges, immunities and facilities granted by the
              government in the same order as the Vienna convention.
              <br />
              The Vienna Convention on Diplomatic relations was established on
              18 April 1961 and entered into force in 1964, and was signed by
              190 states until June 2015.
              <br />
              In accordance with the Vienna Convention, the united state of
              America assigned absolute immunity to the arbitrator, also the
              British, German, Norwegian legislation gave absolute immunity to
              the arbitrators.
              <br />
              Canadian law also granted immunity to the judge in comparison with
              the public judge.
              <br />
              Rules of the international supreme court for dispute resolution:
              <br />
              The international s uapreme court for dispute resolution follows
              the UNCITRAI arbitration rules as revised in 2010. General
              assemblyresolution 65/22 and the revised 2013 resolution number
              68/109 in a resolution adopted by the General Assembly on 16
              December 2013.
              <br />
              The UNCITRAI arbitration rules were adopted by the United Nation
              General Assembly in 1976 afte deliberations and intensive
              consultations with several international rganizations and experts
              in the field of arbitration. These consultations were conducted
              under the auspices of the United Nations commission on
              International Trade Law (UNCITRAL).
              <br />
              The UNCITRAI rules in 2010 to reflect the evolution of arbitral
              practices since the adoption of the rules in 1976 and the rules
              were revised again in 2013 to add UNCITRAI rules on transparency
              in treaty arbitration between investors and states.
              <br />
              Also the international supreme court for dispute resolution works
              in mediation according to the international rules.
            </p>
          </div>
        </Collapse>
      </div>
    </>
  );
}
