import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { fetchApi } from "../../Utils/Connections";

const mapStateToProps = (state: any) => {
  return {
    admin: state.admin,
    path: state.path,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAdmin: (admin: any) => dispatch({ type: "SET_ADMIN", payload: admin }),
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Admin = ({ admin, setAdmin, path, setPath }: any) => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const Login = async () => {
    setLoading(true);
    const result = await fetchApi(`Admin/Login`, "POST", {
      UserName: username,
      Password: password,
    });
    if (result.StatusCode === 200) {
      sessionStorage.setItem("token", result.My_Result.JWT_TOKEN);
      setAdmin(true);
      setPath("/admin/dashboard");
      navigate("/admin/dashboard");
    }
    console.log("result", result);
  };

  return (
    <>
      <div className="adminLogin d-flex align-items-center justify-content-center">
        <div
          className="card shadow border-0 p-3 text-center"
          style={{ width: "30rem", maxWidth: "95%" }}>
          <h3>ICDR - ISCDR Admin </h3>
          <div>
            <div>
              <input
                type="text"
                className="form-control rounded"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control  rounded"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className={`bi bi-${!showPassword ? "eye" : "eye-slash"}`}
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginLeft: "-2rem", padding: "0.5rem" }}
              />
            </div>
          </div>

          <button
            className="btn btn-primary btn-block rounded mt-2"
            onClick={Login}>
            Login
          </button>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
