import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { aboutText } from "../Utils/sample";

const mapStateToProps = (state: any) => {
  return {
    modal: state.modal,
    navigation: state.navigation,
    path: state.path,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setModal: (modal: boolean) =>
      dispatch({ type: "SET_MODAL", payload: modal }),
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Footer = ({ path, modal, setModal, navigation, setPath }: any) => {
  const navigate = useNavigate();

  const navigateTo = (route: string) => {
    sessionStorage.setItem("selectedRoute", route);
    setPath(route);
    window.scrollTo(0, 0);
    navigate(route);
  };
  console.log("path", path);

  React.useEffect(() => {
    setPath(sessionStorage.getItem("selectedRoute")!);
  }, []);

  return (
    <>
      <hr className="mx-5" />

      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="mb-5">
                <h3 className="footer-heading mb-4">About Us</h3>
                <p>
                  {path === "/iscdr"
                    ? "The International Supreme Court for dispute resolution is an international entity whose objective is to settle disputes peacefully through, conciliation, negotiations, mediation, arbitration and others..."
                    : aboutText}
                </p>

                {path !== "/" && (
                  <div>
                    {path !== "/iscdr" && (
                      <div>
                        <i className="bi bi-envelope" aria-hidden="true"></i>
                        <span className="ml-2">
                          <a href="mailto:Icdr.uk@outlook.com">
                            Icdr.uk@outlook.com
                          </a>
                        </span>
                      </div>
                    )}
                    {path === "/iscdr" && (
                      <div>
                        <i className="bi bi-envelope" aria-hidden="true"></i>
                        <span className="ml-2">
                          <a href="mailto:info@iscdr.com">info@iscdr.com</a>
                        </span>
                      </div>
                    )}
                    <div>
                      <i className="fa fa-fax" aria-hidden="true"></i>
                      <span className="ml-2">
                        <a href="tel:+44 77 6799 9491">+44 77 6799 9491</a>
                      </span>
                    </div>
                    <div>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      <span className="ml-2">
                        153-159 Bow Road Bow Business Center London E3 2SE
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-4 mb-5 mb-lg-0">
              {path !== "/" && (
                <>
                  <div className="row mb-5">
                    <div className="col-md-12">
                      <h3 className="footer-heading mb-4">Navigations</h3>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <ul className="list-unstyled">
                        {navigation.map((item: any, index: number) => {
                          return (
                            <li
                              key={index}
                              className={path === item.path ? "active" : ""}>
                              <img height="25" width="25" src={item?.logo} />

                              <button onClick={() => navigateTo(item.path)}>
                                {item.name}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <ul className="list-unstyled">
                        <li>
                          <button onClick={() => setModal(true)}>
                            Membership
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-12">
                      <h3 className="footer-heading mb-4">Follow Us</h3>

                      <div>
                        <a href="#" className="pl-0 pr-3">
                          <span className="icon-facebook"></span>
                        </a>
                        <a href="#" className="pl-3 pr-3">
                          <span className="icon-twitter"></span>
                        </a>
                        <a href="#" className="pl-3 pr-3">
                          <span className="icon-instagram"></span>
                        </a>
                        <a href="#" className="pl-3 pr-3">
                          <span className="icon-linkedin"></span>
                        </a>
                      </div>
                    </div>
                  </div> */}
                </>
              )}
            </div>

            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="mb-5">
                {/* <h3 className="footer-heading mb-4">Watch Live Streaming</h3> */}

                <div className="block-16">
                  <figure>
                    <img
                      src={
                        path !== "/iscdr"
                          ? "/assets/images/icdrlogo.png"
                          : "/assets/images/iscdrlogo.png"
                      }
                      alt="placeholder"
                      className="img-fluid rounded"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-5 text-center">
            <div className="col-md-12">
              <p>
                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script> All
                rights reserved | Made by
                <a href="https://asynk.tech" target="_blank">
                  {" "}
                  Asynk Tech
                </a>
                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
