import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { fetchApi } from "../../Utils/Connections";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
    setValidCode: (valid: boolean) =>
      dispatch({ type: "SET_VALID_CODE", payload: valid }),
  };
};

const Register = ({ path, setPath, setValidCode }: any) => {
  const [showCode, setShowCode] = React.useState<boolean>(false);
  const [code, setCode] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const register = async () => {
    sessionStorage.setItem("code", code);
    setLoading(true);
    const result = await fetchApi(`Public/GetUserByCode?Code=${code}`, "GET", {
      code: "1234",
    });
    console.log(result);
    if (result.StatusCode === 200) {
      sessionStorage.setItem("member", JSON.stringify(result.My_Result));
      sessionStorage.setItem("valid", "true");
      sessionStorage.setItem("selectedRoute", "/home");
      setValidCode(true);
      setPath("/home");
      navigate("/home");
    } else {
      alert("Invalid Code");
    }
    setLoading(false);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    sessionStorage.setItem("selectedRoute", "/");
    setPath("/");
  }, []);

  return (
    <div className="bg-light">
      <div className="h-100 d-flex justify-content-center p-3 ">
        <img src="/assets/images/icdrlogo.png" alt="" className="mw-100" />
      </div>
      {loading && (
        <div className="d-flex justify-content-center pb-5">
          <Spinner />
        </div>
      )}
      <div className="container d-flex align-items-center pt-1 mb-4 ">
        <input
          type={showCode ? "text" : "password"}
          className="form-control rounded"
          value={code}
          placeholder="Enter your Membership Code"
          onChange={(e) => setCode(e.target.value)}
        />
        <i
          className={`bi bi-eye${showCode ? "-slash" : ""}`}
          onClick={() => setShowCode(!showCode)}
          style={{ marginLeft: "-2rem", padding: "0.5rem" }}></i>
        <button
          className="btn mx-2 btn-primary rounded"
          style={{ padding: "0.65rem" }}
          onClick={() => {
            register();
          }}
          disabled={loading}>
          Check
        </button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
