import React from "react";
import { Collapse } from "react-bootstrap";

export default function IntroductoryRules() {
  const [open, setOpen] = React.useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const openCollapse = (index: number) => {
    let temp = [...open];
    temp[index] = !temp[index];
    setOpen(temp);
  };
  return (
    <div>
      <div className="text-center mb-5">
        <h2>
          Introductory <span className="text-primary">Rules</span>
        </h2>
      </div>
      <ul className="text-center" style={{ listStyle: "none", padding: 0 }}>
        Article 1
        <li>
          Where the parties have agreed that their disputes with respect to the
          specific law of the relationship, whether contractual or not, shall be
          referred to arbitration under the ICDR Rules and then such disputes
          shall be settled in accordance with these Rules subject to the
          modification that the parties may agree to.
        </li>
        <li>
          Where the parties have agreed to submit their disputes to arbitration
          under the rules, they shall be deemed to have submitted their disputes
          to the rules in force on the date of commencing the arbitration
          proceedings, unless otherwise agreed.
        </li>
        <li>
          The two parties may agree to choose the general rules of arbitration
          or the urgent arbitration rules, provided that they make the choice
          before the appointment of the arbitral tribunal, and if the selection
          is after their appointment, their consent must be obtained.
        </li>
        <li>
          The Center administers the resolution of disputes under the Rules by
          ICDR Arbitration when required to do so, as the designating authority
          under the Proceedings Ad Hoc. These Arbitration Rules shall apply
          unless any of these Rules conflict with a provision of the law
          applicable to the arbitration which the parties cannot rescind, that
          provision is the one that prevails.
        </li>
        <li>
          Decree No. 1 establishing the Center and the statute attached to it
          defines and regulates the powers of the President, Board of Trustees,
          Executive and Subcommittee.
        </li>
      </ul>
      <div
        className="card my-3 p-2 shadow border-0"
        onClick={() => openCollapse(0)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Notice and Calculation of{" "}
            <span className="text-primary">Periods of Time</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[0] ? "up" : "down"}`}
            onClick={() => openCollapse(0)}></i>
        </div>

        <Collapse in={open[0]}>
          <ol className="">
          Article 2
            <li>
              Any notice or suggestion may be sent by any available means of
              communication that provides or allows recording of the
              transmission.
            </li>
            <li>
              If an address has been specified by a party designated for this
              purpose or authorized by the arbitral tribunal, any notice shall
              be delivered to that party at that address, and if delivered shall
              be deemed to have been received. Delivery by electronic means such
              as fax or e-mail may be sent to the address designated or
              authorized from time to time by the concerned party.
            </li>
            <li>
              In the absence of such authorization, notice is deemed to have
              been received if Physically delivered to the receiver. NS . It is
              delivered at the place of business or habitual residence or the
              postal address of the receiver.
            </li>
            <li>
              If, after the efforts of a responsible person, in accordance with
              paragraphs 2 or 3, delivery is not made, the notice shall be
              deemed to have been received if it is sent to a destination known
              to the addressee as the place of business or habitual residence or
              postal address, by registered letter or by any other means
              providing a record of delivery.
            </li>
            <li>
              Notice is deemed to have been received on the day it was delivered
              in accordance with paragraphs 2, 3 or 4. 6. For the purpose of
              calculating the time period under these Rules, work begins on the
              day following the day on which notice is received If the last day
              of this period is an official holiday at the addressee's residence
              or place of business, the period is extended until the first
              following working day.
            </li>
          </ol>
        </Collapse>
      </div>

      <div
        className="card my-3 p-2 shadow border-0"
        onClick={() => openCollapse(1)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Notice of <span className="text-primary">arbitration</span>{" "}
          </h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[1]}>
          <ol>
            Article 3
            <li>
              The party(ies) who initiated the arbitration (the “Claimant”)
              shall at the same time inform the other party(ies) (the
              “Respondent”) by giving notice to the Center
            </li>
            <li>
              The arbitration proceedings shall be deemed to have commenced on
              the date of the notification. The arbitration shall be received by
              the court.
            </li>
            <li>
              Notice of arbitration may be submitted by regular mail or e-mail
              to the respondent and by post, e-mail or via the Center's website.
              The notice of the arbitration form will include the following
              information:
              <ol type="a">
                <li>Request to refer the dispute to arbitration.</li>
                <li>Names and contact details of the parties.</li>
                <li>Determine which arbitration agreement is invoked.</li>
                <li>
                  Brief description of the nature of the dispute and claim.
                </li>
                <li>
                  Determine the amount involved. And. Remedy or solution
                  required.
                </li>
                <li>
                  A proposal for the number of arbitrators and the language and
                  place of arbitration, if any, that the parties have not
                  previously agreed on these aspects
                </li>
                <li>
                  Determine the payment of the relevant registration fee to the
                  center
                </li>
                <li>
                  A copy of the arbitration agreement and any other contract or
                  legal instrument from which the dispute arises.
                </li>
              </ol>
            </li>
            <li>
              The relevant registration fee is also paid to the center by online
              bank transfer payment or a bank check in favor of the center ICDR
            </li>
            <li>
              The Secretariat of the Center may request the claimant to comply
              with the requirements set forth in Paragraph 3 if the claimant
              fails to comply with these requirements.
            </li>
            <li>
              The formation of the arbitral tribunal may not be obstructed by
              any dispute regarding respect for the sufficiency of the
              arbitration notice, which is finally decided by the arbitration.
            </li>
          </ol>
        </Collapse>
      </div>

      <div
        className="card my-3 p-2 shadow border-0"
        onClick={() => openCollapse(2)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Response to the{" "}
            <span className="text-primary">Notice of Arbitration</span>
          </h3>
          <i className={`bi bi-chevron-${open[2] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[2]}>
          <ol>
            Article 4
            <li>
              Within 21 days of receiving the Notice of Arbitration, Respondent
              must reach out to the other party and file with ICDR in response
              to the Notice of Arbitration. The arbitration shall include:
              <ul>
                <li>Respondent's name and contact details.</li>
                <li>
                  Responding to the information contained in the Notice of
                  Arbitration.
                </li>
              </ul>
            </li>
            <li>
              The response to the Notice of Arbitration may also include:
              <ul>
                <li>
                  Any acknowledgment that is lacking by an arbitral tribunal
                  constituted under these Jurisdiction Rules.
                </li>
                <li>
                  A proposal to appoint a single arbitrator or a group of
                  arbitrators.
                </li>
                <li>
                  A brief description of any counterclaims or claims for
                  compensation, if any, including where appropriate, a reference
                  to the quantities involved.
                </li>
                <li>
                  Notice of arbitration pursuant to Article 3 in the event that
                  the respondent drafts a claim against a party to the
                  arbitration agreement other than the claimant.
                </li>
              </ul>
            </li>
            <li>
              The Secretariat may require the respondent to comply with these
              requirements if the respondent fails to comply with them, and may
              grant additional time for this purpose.
            </li>
            <li>
              The composition of the arbitral tribunal shall not be impeded by
              any dispute regarding respect to the respondent's failure to
              communicate a response to the notice of arbitration, or an
              incomplete or late response to the notice of arbitration, which is
              finally resolved by the arbitral tribunal.
            </li>
          </ol>
        </Collapse>
      </div>

      <div
        className="card my-3 p-2 shadow border-0"
        onClick={() => openCollapse(3)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Representation and <span className="text-primary">Assistance </span>{" "}
          </h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[3]}>
          <div>
            Article 5<br />
            Each party may be assisted by one or more persons whom it chooses by
            name, and the addresses of these persons must be reported to the
            Secretariat. Such communication shall determine whether the
            appointment is for representational or assistance purposes. Where a
            person acts as a representative of a party, the arbitral tribunal,
            on its own initiative or at the request of any party, may at any
            time require that the authority granted to the representative be
            established in such form as the arbitral tribunal may determine.
          </div>
        </Collapse>
      </div>

      <div
        className="card my-3 p-2 shadow border-0"
        onClick={() => openCollapse(4)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Decision Not to Proceed with{" "}
            <span className="text-primary">the Arbitral Proceedings</span>{" "}
          </h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[4]}>
          <div>
            Article 6<br />
            The executive committee may decide not to proceed with the
            arbitration proceedings if it is satisfied that there is no
            agreement between the parties to arbitrate under the rules of the
            center if the executive authority allows the arbitration committee
            to continue with this is not considered acceptance of jurisdiction
            by the arbitral tribunal The arbitral tribunal is still entitled to
            hear any judicial appeal Even if the Executive Committee accepted a
            claim for arbitration.
          </div>
        </Collapse>
      </div>

      <div
        className="card my-3 p-2 shadow border-0"
        onClick={() => openCollapse(5)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Exclusion of <span className="text-primary">Liability </span>{" "}
          </h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[5]}>
          <div>
            Article 7 <br />
            Except for willful infractions, neither the arbitrators, the
            Secretariat, the Tribunal, its employees, the chairperson and
            members of both the Board of Trustees and the Executive Committee
            nor any person appointed by the arbitral tribunal shall be liable to
            any person on the basis of any act or omission in connection with
            the arbitration and by accepting these Rules, the parties waive For
            all these lawsuits the above-mentioned parties.
          </div>
        </Collapse>
      </div>
    </div>
  );
}
