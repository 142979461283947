import React from "react";
import { Collapse } from "react-bootstrap";

export default function ArbitralTribunal() {
  const [open, setOpen] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const openCollapse = (index: number) => {
    let newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };
  return (
    <div>
      <div className="text-center">
        <h2>Composition of the Arbitral</h2>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(0)}>
          <h3>
            Number of <span className="text-primary">Arbitrators</span>
          </h3>
          <i className={`bi bi-chevron-${open[0] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[0]}>
          <div>
            <h4>Article 8</h4>If the parties have not previously agreed on the
            number of arbitrators, and if within 28 days after the respondent
            receives the notice of arbitration and the parties have not agreed
            that there will be three arbitrators, only one arbitrator shall be
            appointed unless the Executive Committee determines that in the
            circumstances three may be used Appropriate judges.
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(1)}>
          <h3>
            Appointment of <span className="text-primary">Arbitrators</span>
          </h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[1]}>
          <div>
            Article 9<br />
            When appointing, the Executive Committee shall take into account the
            considerations to which it is likely to ensure the appointment of an
            independent and impartial arbitrator capable of dealing with the
            issues in the dispute.
            <ol>
              <li>
                If the sole arbitrator is not appointed within the time period
                agreed upon by the parties or, where the parties have not agreed
                to a period of time within 30 days after its receipt, the
                appointment shall be made by the Executive Committee.
              </li>
              <li>
                If three arbitrators are appointed, each party shall appoint one
                arbitrator, and the two arbitrators, once appointed, shall
                appoint the third arbitrator to preside over the session.
              </li>
              <li>
                If within 30 days after receiving the notification of the first
                party appointing an arbitrator of the other party, the first
                party did not notify the arbitrator it appointed, the second
                party shall appoint the arbitrator, at the request of the first
                party, by the Executive Committee.
              </li>
              <li>
                If the two arbitrators fail to do so within 30 days after the
                appointment of the second arbitrator, it is agreed to appoint
                the presiding arbitrator, and the presiding arbitrator shall be
                appointed by the Executive Committee.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(2)}>
          <h3>
            Multiple <span className="text-primary">Parties</span>
          </h3>
          <i className={`bi bi-chevron-${open[2] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[2]}>
          <div>
            <ol>
              Article 10
              <br />
              <li>
                Three arbitrators are appointed and there are multiple parties
                as the claimant or respondent, unless the parties agree on
                another method for appointing the arbitrators, the multiple
                parties must agree jointly, either as plaintiff or defendant, to
                appoint an arbitrator.
              </li>
              <li>
                If the two parties agree that the arbitral tribunal shall
                consist of a number of arbitrators other than one or three, the
                arbitrators shall be appointed in accordance with the method
                agreed upon by the two parties.
              </li>
              <li>
                In the event that the arbitral tribunal is not constituted under
                this article, the executive authority shall form the committee,
                at the request of any party or the arbitral tribunal, and thus,
                it may rescind any appointment or appointment that has already
                been made, and usually each of the arbitrators is appointed and
                one of them appoints a presiding arbitrator.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(3)}>
          <h3>
            Emergency <span className="text-primary">Arbitrator</span>
          </h3>
          <i className={`bi bi-chevron-${open[3] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[3]}>
          <div>
            Article 11 <br />
            In the event of exceptional urgency, at any time prior to the
            formation of the arbitral tribunal, an arbitrator shall conduct
            emergency procedures pending the formation of the arbitral tribunal
            and the following shall apply:
            <ul>
              <li>
                The request must be submitted to the Secretariat in writing.
              </li>
              <li>
                The request shall state the reasons for the need for an
                emergency arbitrator and the specific claim, with reasons, for
                emergency relief.
              </li>
              <li>
                Proof of payment of the relevant court fees must be attached.
              </li>
              <li>
                The Executive Committee has the discretion to accept or reject
                the application and has no obligation to refund the application
                fee.
              </li>
              <li>
                If the request is granted, an interim arbitrator shall be
                appointed by the Executive Committee to conduct the emergency
                procedures in whatever manner he determines is appropriate in
                the circumstances, having regard to the nature of such emergency
                procedures, and the need for each party, if possible to be
                consulted on the emergency relief claim (whether Benefited or
                not benefited) from such an opportunity (the claim), the reasons
                for emergency relief and further requests from the parties (if
                any).
              </li>
              <li>
                The interim arbitrator carrying out the emergency procedure may
                hold any hearing and decide on a claim for emergency relief,
                order or award, as soon as possible, and in all cases no more
                than 14 days after his appointment. Believing, the order or
                reward must comply with these rules and temporarily send an
                arbitrator to the trust and notify the trust to the parties.
              </li>
              <li>
                Any order or relief decision issued by the provisional
                arbitrator in the event of an emergency procedures may
                subsequently be confirmed, changed, discharged, or canceled in
                whole or in part by the arbitral tribunal in the arbitral
                proceedings.
              </li>
            </ul>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(4)}>
          <h3>
            Disclosures and Challenge
            <span className="text-primary"> of Arbitrators</span>
          </h3>
          <i className={`bi bi-chevron-${open[4] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[4]}>
          <div>
            Article 12 - 13
            <br />
            <ol>
              <li>
                When the prospective arbitrator is contacted in connection with
                his appointment as the arbitrator, the prospective arbitrator
                shall promptly disclose in writing in any circumstances likely
                to raise justifiable doubts as to his impartiality or
                independence. The arbitrator shall rule from the time of his
                appointment and throughout the arbitral proceedings in delaying
                the disclosure of any such circumstances or any doubts about the
                duty to disclose a fact, the circumstance or relationship shall
                be interpreted in favor of disclosure.
              </li>
              <li>
                The appointed arbitrator must apply within one week after being
                notified of the appointment, a written declaration confirming
                their impartiality and independence. The arbitrator is appointed
                only when the arbitrator has accepted the mission and after the
                arbitrator has signed the statement of impartiality and
                independence made by the Secretariat.
              </li>
              <li>
                Apart from communications relating to the assignment, the
                arbitrator shall specifically avoid communications with any
                party regarding the arbitration. If any such communication is
                made, the arbitrator shall notify the other parties and
                arbitrators of its content.
              </li>
              The arbitrator shall avoid any act or behavior likely to impede
              the deliberations or delay the resolution of the dispute.
            </ol>
            <div>
              Article 14
              <ol>
                <li>
                  An arbitrator may be challenged in the event of circumstances
                  that give rise to justifiable doubts as to the arbitrator’s
                  impartiality or independence.
                </li>
                <li>
                  A party may not appeal against the arbitrator appointed by him
                  except for reasons that become known after the appointment is
                  made.
                </li>
                <li>
                  In the event the arbitrator fails or finds it impossible to
                  act, the procedure in respect of the arbitrator's appeal shall
                  apply as provided in Article 15.
                </li>
              </ol>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(5)}>
          <h3>
            Replacement of an <span className="text-primary">Arbitrator</span>
          </h3>
          <i className={`bi bi-chevron-${open[5] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[5]}>
          <div>
            Article 15
            <br />
            <ol>
              <li>
                Subject to paragraph 2, in any event the arbitrator must be
                replaced during the course of the arbitral proceedings, a
                replacement arbitrator shall be appointed in accordance with the
                procedure set forth in Articles 9 to 13 that were applicable to
                the appointment of the substitute arbitrator. This procedure is
                applied even if during the process of appointing the arbitrator
                to be replaced, The party failed to exercise its right to
                appoint or participate in the appointment.
              </li>
              <li>
                If, at the request of one of the parties, and with the approval
                of the Executive Committee, the Executive Authority determines
                that in the exceptional circumstances of the case, it would also
                be justified to deny a Party its right to appoint a substitute
                arbitrator, the Executive Authority may, after giving the
                parties and the remaining arbitrators an opportunity to express
                express their views either (a) appoint a replacement arbitrator
                or (b) after the hearings are closed, authorize the other
                arbitrators to proceed with the arbitration and to make any
                award or award.
              </li>
              <li>
                In all cases, the Executive Committee may, without being
                obligated to state its reasons for the decision, refuse to
                appoint any arbitrator due to the absence of any legal or
                contractual condition or previous failure to comply with his
                duties or duties under these rules. Repeat hearings in case of
                replacement of the arbitrator
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(6)}>
          <h3>
            Repetition of Hearings in the Event of the{" "}
            <span className="text-primary"> Replacement of an Arbitrator</span>
          </h3>
          <i className={`bi bi-chevron-${open[6] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[6]}>
          <div>
            Article 16
            <br />
            If the arbitrator is replaced, the procedures shall be resumed at
            the stage in which the arbitrator who was replaced was suspended to
            perform his functions, unless the arbitral tribunal decides
            otherwise.
          </div>
        </Collapse>
      </div>
    </div>
  );
}
