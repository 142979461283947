import React from "react";
import Collapse from "react-bootstrap/esm/Collapse";

export default function ArbitralProceedings() {
  const [open, setOpen] = React.useState<boolean[]>([]);
  const openCollapse = (index: number) => {
    let newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };
  return (
    <>
      <div className="text-center">
        <h2>
          Arbitral <span className="text-primary">Proceedings</span>
        </h2>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(0)}>
          <h3>
            General <span className="text-primary">provisions</span>
          </h3>
          <i className={`bi bi-chevron-${open[0] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[0]}>
          <div>
            Article 17
            <br />
            <ol>
              <li>
                Subject to these rules, the arbitral tribunal may conduct the
                arbitration in the manner deemed appropriate, provided that the
                parties are treated on an equal footing, and that at the
                appropriate stage of the proceedings each party is given an
                equal and full opportunity to present its case. The arbitral
                tribunal shall, in the exercise of its discretion, conduct
                procedures to avoid unnecessary delays and expenses and to
                provide a fair and efficient dispute resolution process for the
                parties.
              </li>
              <li>
                As soon as possible and after the parties have been invited to
                express opinions, the arbitral tribunal shall determine the
                provisional timetable for arbitration. The arbitral tribunal
                may, at any time, after inviting the parties to express their
                views, extend or shorten any period of time provided under these
                Rules or agreed upon by the parties.
              </li>
              <li>
                If a party so requests at an appropriate stage of the
                proceedings, the arbitral tribunal shall hold hearings for the
                presentation of evidence by witnesses, including expert
                witnesses, or for oral argument. In the absence of such a
                request, the arbitral tribunal shall decide whether to hold such
                hearings or whether the proceedings shall be conducted on the
                basis of documents and other materials.
              </li>
              <li>
                All parties shall communicate all communications to the arbitral
                tribunal by that party to all other parties and the court.
                Likewise, all communications from the arbitral tribunal to the
                parties are copied to the court. These communications shall take
                place at the same time, unless otherwise permitted by the
                arbitral tribunal if it is permitted under that law. If agreed
                in writing by all parties and the arbitral tribunal, all
                communications referred to under this Article as well as all
                related requests shall be uploaded to the website of the server
                center under the secured key and passwords provided by the
                Secretary to the parties and the arbitral tribunal. The arbitral
                tribunal and the parties must have remote access to the server
                if everything is chosen by the parties and the arbitral tribunal
                in writing to use this service.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(1)}>
          <h3>Joinder</h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[1]}>
          <div>
            Article 18
            <br />
            The arbitral tribunal may, at the request of any party, permit the
            joining of one or more third persons in the arbitration as a party
            provided that such person is a party to the arbitration agreement,
            unless, in the opinion of the arbitral tribunal, after giving all
            parties, including the person or persons to be joined, to chance For
            listening, an inclusion should not be allowed due to prejudice to
            any of them. The arbitral tribunal may issue one or more decisions
            in relation to all parties that participate in the arbitration.
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(2)}>
          <h3>
            Seat of <span className="text-primary">Arbitration</span>
          </h3>
          <i className={`bi bi-chevron-${open[2] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[2]}>
          <div>
            Article 19
            <br />
            <ol>
              <li>
                If the parties have not previously agreed on the seat of
                arbitration, then the seat of arbitration shall be the seat of
                the International Supreme Court for Dispute Resolution.
              </li>
              <li>
                The arbitral tribunal may meet at any place it deems appropriate
                for deliberations. Unless the parties agree otherwise, the
                arbitral tribunal may meet at any place it considers suitable
                for any other purpose, including hearings.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(3)}>
          <h3>
            Arbitration <span className="text-primary">Language</span>
          </h3>
          <i className={`bi bi-chevron-${open[3] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[3]}>
          <div>
            Article 20
            <br />
            <ol>
              <li>
                In the absence of agreement between the parties, the language of
                the arbitration shall be English, unless the arbitral tribunal
                or the Executive Committee before the appointment of the
                arbitral tribunal determines, due to exceptional circumstances,
                a different language or languages to be used in the proceedings.
                This decision applies to the statement of claim, statement of
                defense, and any other written statements and, if oral hearings
                hear a venue, the language or languages to be used in such
                hearings.
              </li>
              <li>
                The arbitral tribunal may order that any documents attached to
                the statement of claim or the statement of defense, and any
                supplementary documents or exhibits presented at the session,
                must be accompanied by the proceedings in their original
                language accompanied by a translation in the language or
                languages agreed upon by the parties or specified by the
                arbitral tribunal.
              </li>
              <li>
                All notices, applications and other documents related to the
                Center's interest must be submitted in English, French, Spanish
                or Arabic.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(4)}>
          <h3>
            Statement of <span className="text-primary">Claim</span>
          </h3>
          <i className={`bi bi-chevron-${open[4] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[4]}>
          <div>
            Article 21
            <br />
            <ol>
              <li>
                The claimant shall send its statement of invitation in writing
                to the respondent and to each of the arbitrators and the Center
                within a period of time determined by the arbitral tribunal. The
                claimant may elect to treat the notice of arbitration referred
                to in Article 3 as a statement of claim, provided that the
                notice of arbitration also complies with the requirements of
                paragraphs 2 and 3 of this matter.
              </li>
              <li>
                The statement of claim must include the following details:{" "}
                <ul>
                  <li> Names of the parties and their contact details.</li>
                  <li>
                    A statement of the facts supporting the claim with all
                    documents, documents and evidence relied upon by the
                    claimant to support the claim.
                  </li>
                  <li>Points you think are at odds.</li>
                  <li>He sought redress.</li>
                  <li>The legal basis or arguments in support of the claim.</li>
                </ul>
              </li>
              <li>
                A copy of any contract or other legal instrument from which the
                dispute arises or in relation to it, and the arbitration
                agreement shall be attached to the statement of the case.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(5)}>
          <h3>
            The defendant <span className="text-primary"></span>
          </h3>
          <i className={`bi bi-chevron-${open[5] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[5]}>
          <div>
            Article 22
            <br />
            <ol>
              <li>
                The defendant must submit a statement of defense in writing to
                the plaintiff, to each of the arbitrators and the Center within
                a period of time determined by the arbitral tribunal. A
                respondent may treat its response to a notice of arbitration
                referred to in Article 4 as a statement of defense, provided
                that the response to a notice of arbitration also complies with
                the requirements of paragraphs 1 and 2 of this Article.
              </li>
              <li>
                The statement of defense is attached to the statement of the
                case (Article 22, paragraph 2). The statement of defense shall,
                as far as possible, accompany with all documents and other
                evidence upon which the defendant relied, or contain references
                to it.
              </li>
              <li>
                In a statement of defense, or at a later stage of the
                arbitration proceeding (provided the arbitral tribunal grants
                leave), the respondent may file a counterclaim or rely on a
                claim for the purpose of set-off provided that the arbitral
                tribunal has jurisdiction over it.
              </li>
              <li>
                The provisions of paragraphs 2 and 3 of Article 22 shall apply
                to a counterclaim, which is a claim under paragraph (2) (c) of
                Article 4 and the claim on which it is based for the purpose of
                set-off.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(6)}>
          <h3>
            Amendments to the{" "}
            <span className="text-primary">Claim or Defense</span>
          </h3>
          <i className={`bi bi-chevron-${open[6] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[6]}>
          <div>
            Article 23
            <br />A party may, during the arbitral proceedings, modify or
            supplement its claim or defense, including a counterclaim or claim
            for the purpose of compensation, on such terms as the arbitral
            tribunal may provide unless the arbitral tribunal considers it
            inappropriate to allow such modification or supplementation subject
            to delay To make it or prejudice other parties or any other party
            circumstances. However, a claim or defense, including a counterclaim
            or a claim for the purpose of a set-off, may not be modified or
            supplemented in a modified or supplementary manner. The claim or
            defense falls outside the jurisdiction of the arbitral tribunal. In
            all these cases where the amendment is allowed, the other party is
            given an opportunity to respond to it with these conditions as
            directed by the arbitral tribunal.
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(15)}>
          <h3>
            Please as to the Jurisdiction{" "}
            <span className="text-primary">of the Arbitral Tribunal</span>
          </h3>
          <i className={`bi bi-chevron-${open[15] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[15]}>
          <div>
            Article 24
            <br />
            <ol>
              <li>
                The arbitral tribunal shall have the power to rule on its
                jurisdiction, including any objections to the existence or
                validity of the arbitration agreement. For this purpose, the
                arbitration clause which forms part of the contract shall be
                treated as an agreement regardless of the other terms of the
                contract. A decision issued by the arbitral tribunal is null and
                void and does not automatically invalidate the arbitration
                clause.
              </li>
              <li>
                A petition must be made that the arbitral tribunal has no
                jurisdiction to respond to the notice of arbitration or in
                connection with a counterclaim or a purpose-set claim in the
                defense of the counterclaim or claim for the purpose of
                compensation. The party is not prevented from raising such a
                petition by the fact that it has appointed or participated in
                the appointment of an arbitrator. The argument that the arbitral
                tribunal is beyond its jurisdiction is raised to the authority
                as soon as the matter is outside its power raised during the
                arbitral proceedings. The arbitral tribunal may, in both cases,
                accept a later plea if the delay is deemed justified.
              </li>
              <li>
                The arbitral tribunal may decide on the petition referred to in
                paragraph 2 either before deciding on merit or its award on the
                merits. However, where it believes it is appropriate to do so,
                the arbitral tribunal may continue the arbitral proceedings and
                issue an award notwithstanding any objection pending to its
                jurisdiction before the Court.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(7)}>
          <h3>
            Other Written <span className="text-primary">Submissions</span>
          </h3>
          <i className={`bi bi-chevron-${open[7] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[7]}>
          <div>
            Article 25
            <br />
            The arbitral tribunal shall decide which other written submissions,
            including but not limited to statements and pleadings, in addition
            to the statement of claim and statement of defense shall be required
            or may be presented by the parties and shall specify the time
            periods for communicating such statements.
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(8)}>
          <h3>
            Periods of <span className="text-primary">Time</span>
          </h3>
          <i className={`bi bi-chevron-${open[8] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[8]}>
          <div>
            Article 26
            <br />
            <ol>
              <li>
                Subject to any provision to the contrary in this document, the
                time periods fixed by the arbitral tribunal for the
                communication of written statements (including the statement of
                claim and the statement of defense) shall not exceed 45 days.
                However, the arbitral tribunal may extend any time limit if it
                concludes that the extension has justified.
              </li>
              <li>
                The Executive Committee may extend any time limit, on its own
                initiative, if it considers such extension justified.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(9)}>
          <h3>
            Interim <span className="text-primary">Measures</span>
          </h3>
          <i className={`bi bi-chevron-${open[9] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[9]}>
          <div>
            Article 27
            <br />
            <ol>
              <li>
                The arbitral tribunal may, at the request of a party, grant
                interim measures including but not limited to permanent final
                injunctions.
              </li>
              <li>
                An interim measure is any interim measure by which at any time
                prior to the rendering of the arbitral award in which the
                dispute is finally settled, the arbitral tribunal orders a party
                to which, but not limited to:
                <ul>
                  <li>
                    Maintaining or restoring the status quo pending resolution
                    of the conflict.
                  </li>
                  <li>
                    Taking measures that would prevent or refrain from taking an
                    action that is likely to cause current or imminent harm or
                    prejudice the arbitration process itself.
                  </li>
                  <li>
                    Provide a means to save assets that may be a later reward.
                  </li>
                  <li>
                    Preserve evidence that may be relevant and material to the
                    resolution of the dispute.
                  </li>
                  <li> The order to sell assets that may be damaged.</li>
                  <li>
                    The order to guarantee the costs and fees of the arbitration
                    proceedings.
                  </li>
                  <li>
                    Warranty request in connection with any claim or
                    counterclaim.
                  </li>
                  <li>
                    He orders the establishment of escrow accounts for the
                    suspended funds pending defining the procedures.
                  </li>
                </ul>
              </li>
              <li>
                Subject to any provision to the contrary in these Rules, a party
                requesting an interim measure under paragraph 2, the arbitral
                tribunal shall be satisfied that:
                <ul>
                  <li>
                    Damage that is not adequately repairable by awarding damages
                    (including mitigation of losses) is likely to result if the
                    measure is not taken, and that harm substantially outweighs
                    the harm likely to be caused to the party against whom the
                    measure is directed if the measure was granted.
                  </li>

                  <li>
                    In circumstances in which the arbitral tribunal believes it
                    is appropriate there is a reasonable possibility that the
                    requesting party will succeed on the merits of the claim.
                    The decision on this possibility does not affect the
                    discretion of the arbitral tribunal in making any subsequent
                    decision.
                  </li>
                </ul>
              </li>{" "}
              <li>
                With respect to a request for an interim measure under paragraph
                2(d) to (f), the requirements in paragraphs 3(a) and (b) apply
                only to the extent that the arbitral tribunal considers
                appropriate.
              </li>
              <li>
                The arbitral tribunal may modify, suspend or terminate the
                temporary measure it has granted, at the request of any party,
                or in exceptional circumstances and upon prior notification to
                the parties, on the initiative of the special arbitral tribunal.
              </li>
              <li>
                The arbitral tribunal may require the party requesting an
                interim measure to provide adequate security in connection with
                the measure.
              </li>
              <li>
                The arbitral tribunal may immediately request any party to
                disclose any material change in the circumstances on the basis
                of which the temporary measure was requested or granted.
              </li>
              <li>
                The party requesting an interim measure may be liable for any
                costs and damages resulting from that prevailing at the time the
                interim measure was granted, the measure should not be certain.
                The arbitral tribunal may, at the request of any party, award
                such costs and damages at any time during the proceedings
              </li>
              <li>
                If the temporary measure is not voluntarily applied by the
                parties, any party may approach the competent court for
                assistance.
              </li>
              <li>
                Interim measures may not be requested by any party to a judicial
                authority deemed inconsistent with the arbitration agreement, or
                as a waiver of that agreement.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(10)}>
          <h3>
            Article <span className="text-primary">29</span>
          </h3>
          <i className={`bi bi-chevron-${open[10] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[10]}>
          <div>
            Article 28
            <br />
            <ol>
              <li>
                Each party bears the burden of proving the facts adopted to
                support its claim or defense.
              </li>
              <li>
                Witnesses, including expert witnesses, brought by the parties to
                testify The arbitral tribunal in any issue of facts or expertise
                may be any individual, regardless of that individual being a
                party to the arbitration or in any way related to a party.
              </li>
              <li>
                At any time during the arbitration procedures, the arbitral
                tribunal may request the parties to produce documents, exhibits
                or other evidence within a period of time, such as determined by
                the center.
              </li>
              <li>
                The arbitral tribunal determines the admissibility, relevance,
                objectivity and weight of providing evidence.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(11)}>
          <h3>
            Hearings <span className="text-primary"></span>
          </h3>
          <i className={`bi bi-chevron-${open[11] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[11]}>
          <div>
            Article 29
            <br />
            <ol>
              <li>
                Subject to the provisions relating to emergency arbitrators, in
                the event of an oral hearing, the arbitral tribunal shall give
                the parties sufficient advance notice of the date, time and
                place.
              </li>
              <li>
                Witnesses, including expert witnesses, may be heard under the
                circumstances and examined in the manner determined by the
                arbitral tribunal and in compliance with relevant laws.
              </li>
              <li>
                Hearings shall be confidential, unless the parties agree
                otherwise. The arbitral tribunal may require the retirement of
                any witness or witnesses, including expert witnesses, during the
                testimony of such other witnesses.
              </li>
              <li>
                The arbitral tribunal may order the examination of witnesses,
                including expert witnesses, by means of communications (such as
                videoconferencing).
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(16)}>
          <h3>
            Experts Appointed by the{" "}
            <span className="text-primary">Arbitral Tribunal</span>
          </h3>
          <i className={`bi bi-chevron-${open[16] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[16]}>
          <div>
            Article 30
            <br />
            <ol>
              <li>
                After consulting with the parties, the arbitral tribunal may
                appoint one or more independent experts to inform it, in
                writing, on specific issues determined by a court. A copy of the
                expert’s terms of reference determined by the arbitral tribunal.
                The parties shall be notified
              </li>
              <li>
                The expert shall, before accepting the appointment, submit to
                the arbitral tribunal and to the parties' description of his
                qualifications and a statement of impartiality and independence.
                Within the time ordered by the arbitral tribunal, the parties
                shall inform the arbitral tribunal whether they have objections
                to the expert's qualifications, impartiality or independence.
                The arbitral tribunal shall immediately decide whether to accept
                any such objections. After the expert has been appointed, a
                party may object to the expert’s qualifications, impartiality or
                independence only if the objection is on grounds of which the
                party becomes aware after the appointment has been made The
                arbitral tribunal shall immediately decide what measures, if
                any, to be taken.
              </li>
              <li>
                Upon receipt of the expert's report, the arbitral tribunal shall
                send a copy of the submission of a report to the parties, who
                will be given the opportunity to express in writing their
                opinion on the report. A party has the right to examine any
                document in the possession of an accredited expert.
              </li>
              <li>
                At the request of any party, the expert may be heard, after the
                report is delivered, at a hearing where both parties have the
                opportunity to attend and question the expert. At this hearing,
                either party may present expert witnesses in order to testify on
                the points raised.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(12)}>
          <h3>
            Default session <span className="text-primary">default</span>
          </h3>
          <i className={`bi bi-chevron-${open[12] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[12]}>
          <div>
            Article 31
            <br />
            <ol>
              <li>
                If, within the period of time fixed by these Rules or the
                arbitral tribunal, and without showing sufficient reason for the
                claimant not having communicated his statement of claim, the
                arbitral tribunal issues an order for the termination of the
                arbitral proceedings, unless there remain questions which may
                need to be decided and the arbitral tribunal considers it
                appropriate to do so
              </li>
              <li>
                Thus if, within the time period specified by these Rules or the
                arbitral tribunal, and without showing sufficient cause the
                respondent fails to communicate its response to the Notice of
                Arbitration or the statement of defence, the arbitral tribunal
                shall order that the proceedings continue, without such failure
                itself being treated as an admission of allegations Plaintiff
                The provisions of this paragraph shall also apply to Plaintiff's
                failure to present a defense to a counterclaim or claim for the
                purpose of set-off.
              </li>
              <li>
                If a party, duly notified under these Rules, fails to appear at
                a hearing, without sufficient cause for such failure, the
                arbitral tribunal may proceed with the arbitration.
              </li>
              <li>
                If the arbitral tribunal has duly called for the submission of
                documents, evidence or other evidence, he fails to do so within
                the specified period of time, without showing sufficient reason
                for that in the event of failure, the arbitral tribunal may
                issue a decision on the evidence before it.
              </li>
              <li>
                If a party orders the submission of certain documents and fails,
                without showing sufficient reason, to issue such documents, the
                arbitral tribunal shall make the necessary inferences.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(13)}>
          <h3>
            Closure of <span className="text-primary">Hearings</span>
          </h3>
          <i className={`bi bi-chevron-${open[13] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[13]}>
          <div>
            Article 32
            <br />
            <ol>
              <li>
                The hearings are considered closed 14 days after the submission
                of the final application unless the arbitral tribunal decides to
                inquire of the parties if they have any other evidence of the
                presentation or witnesses to be heard or the submissions, and if
                there are no witnesses, the hearings may be declared closed.
              </li>
              <li>
                The arbitral tribunal may, if it deems it necessary due to
                exceptional circumstances, decide, on its own initiative or at
                the request of one of the parties, to reopen the hearings at any
                time before the award is presented.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(14)}>
          <h3>
            Waiver of the <span className="text-primary">right to object</span>
          </h3>
          <i className={`bi bi-chevron-${open[14] ? "up" : "down"}`}></i>
        </div>
        <Collapse in={open[14]}>
          <div>
            Article 33
            <br />
            The failure of a party to immediately object to a failure to comply
            with these Rules or with any of them shall be deemed a provision of
            the arbitration agreement a waiver of that party's right to file
            such objection, unless that party can demonstrate that, under the
            circumstances, its failure to object has had justified.
          </div>
        </Collapse>
      </div>
    </>
  );
}
