import React, { useEffect } from "react";
import { fetchApi } from "../../Utils/Connections";
import { connect } from "react-redux";
import Spinner from "../Spinner/Spinner";

const mapStateToProps = (state: any) => {
  return {
    AdminModal: state.AdminModal,
    selectedMember: state.selectedMember,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAdminModal: (AdminModal: boolean) =>
      dispatch({ type: "SET_ADMIN_MODAL", payload: AdminModal }),
  };
};

const AdminModal = ({
  AdminModal,
  selectedMember,
  setAdminModal,
  mode,
}: any) => {
  const [loading, setLoading] = React.useState(false);

  const [memberToUpate, setMember] = React.useState<any>(null);

  const deleteMember = async (member: any) => {
    setLoading(true);
    const result = await fetchApi(`Admin/DeleteMember`, "DELETE", {
      IdUser: member.IdUser,
    });
    if (result.StatusCode === 200) {
      setAdminModal(false);
      window.location.reload();
    }
    setLoading(false);
  };

  const editMember = async (member: any) => {
    if (
      memberToUpate.FullName === "" ||
      memberToUpate.Position === "" ||
      memberToUpate.Code === ""
    )
      return alert("Please fill all the fields");
    setLoading(true);
    const result = await fetchApi(`Admin/UpdateMember`, "PUT", {
      IdUser: memberToUpate.IdUser,
      FullName: memberToUpate.FullName,
      Position: memberToUpate.Position,
      Code: memberToUpate.Code,
    });
    if (result.StatusCode === 200) {
      setAdminModal(false);
      window.location.reload();
    }
    setLoading(false);
  };

  const addMember = async (member: any) => {
    if (
      memberToUpate.FullName === "" ||
      memberToUpate.Position === "" ||
      memberToUpate.Code === ""
    )
      return alert("Please fill all the fields");
    setLoading(true);
    const result = await fetchApi(`Admin/InsertUser`, "POST", {
      FullName: memberToUpate.FullName,
      Position: memberToUpate.Position,
      Code: memberToUpate.Code,
    });
    if (result.StatusCode === 200) {
      setAdminModal(false);
      window.location.reload();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (mode === "add")
      setMember({
        FullName: "",
        Position: "",
        Code: "",
        IdUser: 0,
      });
  }, [mode]);

  React.useEffect(() => {
    if (selectedMember !== null) setMember(selectedMember);
    console.log("selectedMember", selectedMember);
  }, [selectedMember]);

  return (
    <>
      <div
        className={`backdrop ${AdminModal && "active"}`}
        onClick={() => {
          setAdminModal(false);
        }}></div>

      <div className={`icdr-modal ${AdminModal && "active"} `}>
        {memberToUpate !== null && mode === "edit" && (
          <div className="p-3">
            <h3>Edit Member</h3>
            <i
              className="bi bi-x close"
              onClick={() => {
                setAdminModal(false);
              }}></i>
            <label htmlFor="name" className="m-0">
              FullName
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter the name here"
              value={memberToUpate.FullName}
              className="form-control rounded mb-2"
              onChange={(e: any) => {
                setMember({
                  ...memberToUpate,
                  FullName: e.target.value,
                });
              }}
            />
            <label htmlFor="position" className="m-0">
              Position
            </label>

            <input
              placeholder="Enter the position here"
              type="text"
              name="position"
              value={memberToUpate.Position}
              className="form-control rounded"
              onChange={(e: any) => {
                setMember({
                  ...memberToUpate,
                  Position: e.target.value,
                });
              }}
            />
            <label htmlFor="position" className="m-0">
              Code
            </label>

            <input
              placeholder="Enter the Code here"
              type="text"
              name="position"
              value={memberToUpate.Code}
              className="form-control rounded"
              onChange={(e: any) => {
                setMember({
                  ...memberToUpate,
                  Code: e.target.value,
                });
              }}
            />
            {loading && (
              <div className="d-flex justify-content-center mb-5">
                <Spinner />
              </div>
            )}
            <div className="d-flex">
              <button
                className="btn btn-primary btn-block mt-3 rounded mr-1"
                disabled={loading}
                onClick={() => editMember(selectedMember)}>
                Edit
              </button>
              <button
                className="btn btn-danger btn-block mt-3 ml-1"
                disabled={loading}
                onClick={() => {
                  deleteMember(selectedMember);
                }}>
                delete
              </button>
            </div>
          </div>
        )}
        {mode === "add" && (
          <div className="p-3">
            <h3>Add Member</h3>
            <i
              className="bi bi-x close"
              onClick={() => {
                setAdminModal(false);
              }}></i>
            <hr />
            <label htmlFor="name" className="m-0">
              FullName
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter the name here"
              className="form-control rounded mb-2"
              onChange={(e: any) => {
                setMember({
                  ...memberToUpate,
                  FullName: e.target.value,
                });
              }}
              value={memberToUpate?.FullName}
            />
            <label htmlFor="position" className="m-0">
              Position
            </label>

            <input
              placeholder="Enter the position here"
              type="text"
              name="position"
              className="form-control rounded"
              onChange={(e: any) => {
                console.log("e", e);

                setMember({
                  ...memberToUpate,
                  Position: e.target.value,
                });
              }}
              value={memberToUpate?.Position}
            />
            <label htmlFor="position" className="m-0">
              Code
            </label>

            <input
              placeholder="Enter the Code here"
              type="text"
              name="position"
              value={memberToUpate?.Code}
              className="form-control rounded"
              onChange={(e: any) => {
                setMember({
                  ...memberToUpate,
                  Code: e.target.value,
                });
              }}
            />
            {loading && (
              <div className="d-flex justify-content-center mb-5">
                <Spinner />
              </div>
            )}
            <div className="d-flex">
              <button
                className="btn btn-primary btn-block mt-3 rounded mr-1"
                disabled={loading}
                onClick={() => addMember(selectedMember)}>
                Add Member
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminModal);
