import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    modal: state.modal,
    navigation: state.navigation,
    path: state.path,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setModal: (modal: boolean) =>
      dispatch({ type: "SET_MODAL", payload: modal }),
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const NavBar = ({ modal, setModal, navigation, setPath, path }: any) => {
  const navigate = useNavigate();

  const navigateTo = (route: string) => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("selectedRoute", route);
    setPath(route);
    navigate(route);
  };

  React.useEffect(() => {
    let temp = sessionStorage.getItem("selectedRoute");
    if (temp) path = temp;
  }, []);

  const [scrolled, setScrolled] = React.useState(false);

  window.addEventListener("scroll", function () {
    let verticalScrollPosition = window.scrollY;
    if (verticalScrollPosition > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });

  return (
    <>
      <div className="">
        <div
          className={`site-navbar ${scrolled ? "scrolled" : "pt-2"} `}
          style={{
            position: "fixed",
            top: 0,
            zIndex: 1000,
            left: 0,
            right: 0,
          }}>
          <div className="container-fluid ">
            <div className="row d-flex align-items-center">
              <div className="col-8 col-md-8 col-lg-4">
                <h1 className="mb-0" onClick={() => navigateTo("/")}>
                  <button onClick={() => navigateTo("/")} className="h2 mb-0">
                    <img
                      src={
                        path === "/iscdr"
                          ? "/assets/images/iscdrlogo.png"
                          : "/assets/images/lweb.png"
                      }
                      alt="logo"
                      height={60}
                      style={{ transform: "translateX(-1rem)" }}
                      className="pl-2"
                    />
                  </button>
                </h1>
              </div>
              <div className="col-4 col-md-4 col-lg-8">
                <nav
                  className="site-navigation text-right text-md-right"
                  role="navigation">
                  <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                    <button className="site-menu-toggle js-menu-toggle text-white">
                      <span className="icon-menu h3"></span>
                    </button>
                  </div>

                  <ul className="site-menu js-clone-nav d-none d-lg-block">
                    {navigation.map((item: any, index: number) => {
                      return (
                        <li
                          key={index}
                          className={path === item.path ? "active" : ""}>
                          <button onClick={() => navigateTo(item.path)}>
                            {item.name}
                          </button>
                        </li>
                      );
                    })}

                    <li className="">
                      <button onClick={() => setModal(true)}>Membership</button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-mobile-menu">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body">
          <ul className="site-nav-wrap">
            {navigation.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className={`js-menu-toggle ${
                    path === item.path ? "active" : ""
                  }`}>
                  <img height="25" width="25" src={item?.logo} />
                  <button onClick={() => navigateTo(item.path)}>
                    {item.name}
                  </button>
                </li>
              );
            })}
            <li className="js-menu-toggle">
              <button onClick={() => setModal(true)}>Membership</button>
            </li>
          </ul>
        </div>
      </div>

      <a
        id="back-to-top"
        className={`back-to-top d-flex align-items-center justify-content-center ${
          scrolled && "active"
        }`}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}>
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
