import React from "react";
import { aboutText } from "../../Utils/sample";

export default function About() {
  return (
    <>
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: "url('/assets/banners/cont.jpg')" }}
        data-aos="fade"
        data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-8 text-center"
              data-aos="fade-up"
              data-aos-delay="400">
              <h1 className="mb-4">About Us</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section section-1 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-md-4">
              <img
                src="/assets/images/icdrlogo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-4">
              <div className="px-lg-3">
                <p className="dropcap">{aboutText}</p>
                <p>
                  Established to provide effective, efficient, and impartial
                  conflict resolution services on a global scale, the ICDR
                  caters to disputes transcending national borders.
                </p>
                <p>
                  It operates with a commitment to neutrality, recognizing the
                  diverse legal, cultural, and procedural backgrounds involved
                  in international disputes.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <p>
                As a leader in the field of dispute resolution, the ICDR offers
                a suite of services tailored for the international community.
              </p>
              <p>
                It integrates best practices and innovative approaches to handle
                complex issues arising in cross-border conflicts.
              </p>
              <p>
                With a panel of international arbitrators and mediators, the
                ICDR ensures that resolutions are conducted by experts familiar
                with the intricacies of international law and multilateral
                negotiations.
              </p>
              <p>
                The ICDR deals with Arbitration, Mediation, Alternative means,
                and Artificial Intelligence Arbitration.
              </p>

              {/* <!-- <blockquote className="blockquote">
              <p>We're committed to success &amp; genuine provider of all types of legal services</p>
            </blockquote> --> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="site-section">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 section-title text-center">
              <h2>Best Attorneys</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia
                eligendi sint corrupti eos dolor numquam sit error fuga
                asperiores laborum!
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="person-1">
                <img src="/images/person_3.jpg" alt="" className="img-fluid" />
                <div className="person-1-contents">
                  <h2>Rodney Stewart</h2>
                  <span className="d-block position">Founder</span>
                  <div className="person-1-social">
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                    <a href="#">
                      <span className="icon-linkedin"></span>
                    </a>
                    <a href="#">
                      <span className="icon-twitter"></span>
                    </a>
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="person-1">
                <img src="images/person_1.jpg" alt="" className="img-fluid" />
                <div className="person-1-contents">
                  <h2>Rodney Stewart</h2>
                  <span className="d-block position">Founder</span>
                  <div className="person-1-social">
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                    <a href="#">
                      <span className="icon-linkedin"></span>
                    </a>
                    <a href="#">
                      <span className="icon-twitter"></span>
                    </a>
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="person-1">
                <img src="/images/person_2.jpg" alt="" className="img-fluid" />
                <div className="person-1-contents">
                  <h2>Rodney Stewart</h2>
                  <span className="d-block position">Founder</span>
                  <div className="person-1-social">
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                    <a href="#">
                      <span className="icon-linkedin"></span>
                    </a>
                    <a href="#">
                      <span className="icon-twitter"></span>
                    </a>
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
