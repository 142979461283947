import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper/modules";

const images: string[] = [
  "./assets/slideShow/icdr1.jpg",
  "./assets/slideShow/icdr2.jpg",
  "./assets/slideShow/icdr3.jpg",
  "./assets/slideShow/380397-4k-wallpaper.jpg",
  "./assets/slideShow/London-GB-flag-small.jpg",
  "./assets/slideShow/london-view.jpg",
  "./assets/slideShow/London.jpg",
];

export default function ImageSwiper() {
  const scrollTo = (selector: string) => {
    const element = document.querySelector(selector);
    element!.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}>
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div
              style={{ backgroundImage: `url('${image}')` }}
              className=" site-blocks-cover overlay"
              data-stellar-background-ratio="0.5"
            />
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <div
            style={{ backgroundImage: `url('./assets/slideShow/photos.jpg')` }}
            className=" site-blocks-cover overlay"
            data-stellar-background-ratio="0.5"
          />
          <p
            style={{
              position: "absolute",
              top: "60%",
              left: "50%",
              zIndex: "1000",
              transform: "translate(-50%, -50%)",
            }}>
            <a
              className="btn btn-primary px-4 py-3"
              onClick={() => {
                scrollTo("#start");
              }}>
              Get Started
            </a>
          </p>
        </SwiperSlide>

        <div
          className="container"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            transform: "translate(-50%, -50%)",
          }}>
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-8 text-center"
              data-aos="fade-up"
              data-aos-delay="400">
              <h1 className="mb-4 text-white">We Fight For Justice</h1>
              <p className="mb-5"></p>
            </div>
          </div>
        </div>
      </Swiper>
    </>
  );
}
