import React from "react";
import { fetchApi } from "../../Utils/Connections";
import { connect } from "react-redux";

import Spinner from "../../components/Spinner/Spinner";
import AdminModal from "../../components/AdminModal/AdminModal";

const mapStateToProps = (state: any) => {
  return {
    adminModal: state.AdminModal,
    selectedMember: state.selectedMember,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setAdminModal: (AdminModal: boolean) =>
      dispatch({ type: "SET_ADMIN_MODAL", payload: AdminModal }),
    setSelectedMember: (selectedMember: any) =>
      dispatch({ type: "SET_SELECTED_MEMBER", payload: selectedMember }),
  };
};

const Dashboard = ({ adminModal, setAdminModal, setSelectedMember }: any) => {
  const [members, setMembers] = React.useState<any[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState("add");

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await fetchApi(`Admin/GetMembers`, "GET", {});
    if (result.StatusCode === 200) {
      setMembers(result.My_Result);
    }
  };

  return (
    <>
      <div className="adminNavbar d-flex justify-content-between align-items-center">
        <img
          src="/assets/images/lweb.png"
          alt="placeholder"
          height="60"
          className="p-2"
        />
        <button
          className="btn btn-primary m-2"
          onClick={() => {
            sessionStorage.removeItem("token");
            window.location.reload();
          }}>
          Log out
        </button>
      </div>
      <div className="container mt-5 pt-5">
        <div className="d-flex justify-content-center mb-5">
          <button
            className="btn btn-primary rounded"
            onClick={() => {
              setMode("add");
              setAdminModal(true);
            }}>
            Add Member
          </button>
        </div>
        <div className="row ">
          {members.length !== 0 &&
            members.map((item: any) => (
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                <div className="person-1">
                  <div className="person-1-contents">
                    <h2>{item.FullName}</h2>
                    <span className="d-block position">
                      Position: {item.Position}
                    </span>
                    <span className="d-block code">Code: {item.Code}</span>
                    <div className="d-flex justify-content-end">
                      <i
                        className="bi bi-pencil-square cursor-pointer"
                        onClick={() => {
                          setMode("edit");
                          setSelectedMember(item);
                          setAdminModal(true);
                        }}></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <AdminModal mode={mode} />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
