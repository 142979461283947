import React from "react";
import { Collapse } from "react-bootstrap";

export default function TheAward() {
  const [open, setOpen] = React.useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const openCollapse = (index: number) => {
    let newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };
  return (
    <div>
      <div className="text-center">
        <h2>
          The <span className="text-primary">Award</span>
        </h2>
      </div>
      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(0)}>
          <h3>
            Decisions <span className="text-primary"></span>
          </h3>
          <i className={`bi bi-chevron-${open[0] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[0]}>
          <div>
            Article 34
            <br />
            <ol>
              <li>
                When there is more than one arbitrator, or another decision of
                the arbitral tribunal is taken by a majority of the arbitrators.
                Failure of Majority Decision in Any Case or Any Arbitral Award
                The presiding arbitrator shall decide the matter or award.
              </li>
              In the case of procedural questions, when there is no majority or
              when the arbitral tribunal is authorized, the presiding arbitrator
              may decide on his own, subject to his review, if the arbitral
              tribunal exists.
              <li>
                If any arbitrator refuses or fails to sign the arbitral award,
                the signatures of the majority or the presiding arbitrator who
                fails to obtain a majority shall be sufficient provided that the
                reason for this is the omitted signature shall be mentioned in
                the award by the majority or by the presiding arbitrator.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(1)}>
          <h3>
            Time Limit of <span className="text-primary">the Award </span>
          </h3>
          <i className={`bi bi-chevron-${open[1] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[1]}>
          <div>
            Article 35
            <br />
            <ol>
              <li>
                The arbitral tribunal issues the final arbitral award 90 days
                from the date of the hearings considered closed unless they are
                extended subject to paragraph 2.
              </li>
              <li>
                The Executive Committee may, subject to a reasoned written
                request from the arbitral tribunal, extend the time limit more
                than once if that is appropriate.
              </li>
              <li>
                Where the applicable law requires that the award be issued
                within a time limit from the date of referring the arbitration
                file to the arbitral tribunal, the arbitral tribunal may, on its
                own initiative, extend the deadline for one additional period
                and the Executive Committee, upon a reasoned request from the
                arbitral tribunal, may extend the time limits for periods
                reasonable additional time, if it considers it necessary for the
                Court to perform its duty.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(2)}>
          <h3>
            Form and Effect of <span className="text-primary"> the Award</span>
          </h3>
          <i className={`bi bi-chevron-${open[2] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[2]}>
          <div>
            Article 36
            <br />
            <p>
              <li>
                The arbitral tribunal may, at its discretion, issue separate
                decisions on different issues at different times.
              </li>
              <li>
                All prizes must be in writing, final and mutually binding. The
                parties implement all prizes without delay.
              </li>
              <li>
                The arbitral tribunal shall determine the reasons on which the
                arbitral award is based.
              </li>
              <li>
                The arbitrators must sign each page and it must include the date
                on which the award was issued and indicate the place of
                arbitration. They must also show a copy of the arbitration
                agreement between the two parties.
              </li>
              <li>
                The award signed by the arbitrators shall be sent to the
                secretariat by the arbitral tribunal in at least 3 original
                copies. The Secretariat shall communicate the award to both
                parties thereafter on an expedited basis. In all cases, the
                arbitral tribunal may not communicate an award directly to the
                parties
              </li>
              <li>
                The award may be announced with the consent of all parties or
                anywhere and to the extent that disclosure of the party is
                required by a legal duty, to protect or pursue a legal right, or
                in connection with{" "}
              </li>
            </p>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(3)}>
          <h3>
            Applicable <span className="text-primary">Law</span>
          </h3>
          <i className={`bi bi-chevron-${open[3] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[3]}>
          <div>
            Article 37
            <br />
            The arbitral tribunal shall apply the rules of law designated by the
            parties as applicable to the substance of the dispute. If the
            parties do not make such an appointment, the arbitral tribunal shall
            apply the law closely relevant to the dispute, taking into account
            any use of trade applicable to the transaction.{" "}
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(4)}>
          <h3>
            Settlement or Other Grounds{" "}
            <span className="text-primary">for Termination</span>
          </h3>
          <i className={`bi bi-chevron-${open[4] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[4]}>
          <div>
            <ol>
              <li>
                Article 38 - 39 - 40
                <br />
                If, prior to rendering the award, the parties agree to settle
                the dispute, the Court shall issue an order to terminate the
                arbitration proceedings or, if at the request of the parties and
                accepted by the Court, record the settlement in the form of an
                award on mutually agreed terms. The arbitral tribunal is not
                obligated to give reasons for that decision. If the settlement
                does not cover the costs of the arbitral tribunal or court, the
                arbitral tribunal may specify and control costs in the award.
              </li>
              <li>
                If, prior to the issuance of the award, the continuation of the
                arbitral proceedings becomes unnecessary or impossible for any
                reason not mentioned in paragraph 1, the arbitral tribunal shall
                inform the parties and the Secretariat of its intention to issue
                an order to terminate the proceedings. The arbitral tribunal
                shall have the power to issue such an order unless there are
                remaining issues which may need to be decided upon and the
                arbitration court deems fit to do so.
              </li>
              <li>
                Copies of the order for the termination of the arbitration
                proceedings or the arbitration decision on the date of the
                arbitration panel shall send the terms agreed upon and signed by
                the arbitrators to the secretariat, after which the secretariat
                sends them to the parties promptly. Where the award is made on
                mutually agreed terms, the provisions of paragraphs 2, 4 and 5
                of Article 37, shall apply.
              </li>
              <h4>Article 40</h4>
              <ol>
                <li>
                  Within 28 days after receipt of the award, a party, with
                  notice to the other party(s), the Secretariat may request the
                  arbitral tribunal to provide an explanation of the award.
                </li>
                <li>
                  If the arbitral tribunal considers the request to be justified
                  (for example because when contemplating it there may be some
                  relevant ambiguity) it must provide the explanation in writing
                  within 45 days after the expiration date of the comment on the
                  request. The interpretation must be part of the
                </li>
              </ol>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(5)}>
          <h3>
            Correction of <span className="text-primary">The Award</span>
          </h3>
          <i className={`bi bi-chevron-${open[5] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[5]}>
          <div>
            <ol>
              <li>
                Article 41
                <br />
                Within 28 days after receiving the award, a party, with notice
                to the other party(s) and the Secretariat may request the
                arbitral tribunal to correct any error in the arbitration award,
                any clerical or typographical error, or any error of the same
                nature. If the arbitration court deems the request justified, it
                must correct it within 45 days of receiving the request.
              </li>
              <li>
                The arbitral tribunal may, within 28 days after notification of
                the arbitral award, make corrections on its own initiative.
              </li>
              <li>
                These corrections must be in writing, and form part of the
                award. The provisions of Article 37 paragraphs 2 to 5 apply.{" "}
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(6)}>
          <h3>
            Additional <span className="text-primary">Award</span>
          </h3>
          <i className={`bi bi-chevron-${open[6] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[6]}>
          <div>
            Article 42
            <br />
            <ol>
              <li>
                Within 28 days after receipt of the award, a party, with notice
                to the other party(s), the Secretariat may request the arbitral
                tribunal to issue an additional award or decision based on
                claims made in the arbitral proceeding but not decided by the
                arbitral tribunal.
              </li>
              <li>
                If the arbitral tribunal considers the request for an award or
                an additional award to be justified, it must submit or complete
                its award within 60 days after the expiration of the date of
                suspension on the request. The arbitral tribunal may, if
                necessary, extend the period of time during which the award must
                be declared.
              </li>
              <li>
                When making such a decision or an additional decision, the
                provisions of paragraph 2 of Article 37
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(7)}>
          <h3>
            Confidentiality and{" "}
            <span className="text-primary">Confidentiality</span>
          </h3>
          <i className={`bi bi-chevron-${open[7] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[7]}>
          <div>
            Article 43
            <ol>
              <li>
                The parties undertake to keep, unless the parties expressly
                agree otherwise in writing, all awards and decisions as well as
                all materials submitted by the parties in arbitration
                proceedings that are not otherwise in the public domain in
                confidence, except and to the extent that disclosure may be
                required by any party pursuant to a legal duty to protect or
                Pursuing a legal right, enforcing or challenging a decision in
                legal procedures before a judicial authority. This undertaking
                also applies to arbitrators, experts appointed by the court, the
                secretary of the arbitral tribunal, the secretariat and the
                court.
              </li>
              <li>
                Likewise, the deliberations of the arbitral tribunal are
                confidential, except to the extent that disclosure may be
                required by a decision of the tribunal
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(8)}>
          <h3>
            Retrieval and Destruction{" "}
            <span className="text-primary">of Documents</span>
          </h3>
          <i className={`bi bi-chevron-${open[8] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[8]}>
          <div>
            Article 44
            <br />
            <ol>
              <li>
                The entity that submits the original documents must request
                their retrieval in writing within 9 months from the date of
                receiving the final decision. The General Secretariat of the
                Executive Committee and the Center will not be responsible for
                any of these documents.
              </li>
              <li>
                All copies of documents submitted by the parties or the arbitral
                tribunal to the Center may be destroyed after 9 months have
                passed from the date of delivering a copy of the result to the
                parties. Due to the Corona pandemic and its grave risks, the
                Supreme Court for Dispute Resolution and the ISCDR Center, in
                accordance with the improved measures of covid19, notify the
                parties and beneficiaries of refraining from receiving any
                requests, reviews, or written and paper submissions. The
                transaction is currently only done electronically, please
                contact us at the e-mail
              </li>
            </ol>
          </div>
        </Collapse>
      </div>

      <div className="card my-3 p-2 shadow border-0">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => openCollapse(9)}>
          <h3>
            Online <span className="text-primary">Arbitration</span>
          </h3>
          <i className={`bi bi-chevron-${open[9] ? "up" : "down"}`}></i>
        </div>

        <Collapse in={open[9]}>
          <div>
            Article 45
            <br />
            The court also has an electronic arbitration system via the Internet
            to practice all arbitration work online Organizing programs,
            conferences, seminars and online protocols for the referee judges
            Advisors and members. And the signing of agreements, protocols and
            treaties with associations, unions, universities, national and
            international chambers of commerce and industry, and regular
            international arbitration centers via the Internet ((Online)) It is
            noteworthy that the Icdr has a number of members, experts,
            arbitrators and judges. The lists of the icdr judges are divided
            into three categories: Category (A) Category (B) Category (C)
          </div>
        </Collapse>
      </div>
    </div>
  );
}
