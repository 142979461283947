import React from "react";
import { Collapse } from "react-bootstrap";
import IscdrArbitration from "../../components/IscdrArbitration/IscdrArbitration";
import AboutTheCourt from "../../components/AboutTheCourt/AboutTheCourt";

export default function Iscdr() {
  const [open, setOpen] = React.useState<boolean[]>([false, false]);

  const openCollapse = (index: number) => {
    let temp = [...open];
    temp[index] = !temp[index];
    setOpen(temp);
  };
  return (
    <>
      <div
        className="site-blocks-cover  overlay "
        style={{
          backgroundImage:
            "url('/assets/images/ab3f98b54255c0eb818a70f00365e975.jpg')",
          height: "50vh",
        }}
        data-aos="fade"
        data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-8 text-center"
              data-aos="fade-up"
              data-aos-delay="400">
              <h1 className="mb-4">ISCDR</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section-2 ">
        <div className="container pt-4">
          <div className="row no-gutters align-items-stretch align-items-center">
            <h2 className="text-center p-3">
              The International Supreme Court for dispute resolution is an
              international entity whose objective is to settle disputes
              peacefully through, conciliation, negotiations, mediation,
              arbitration and others...{" "}
            </h2>
          </div>
        </div>
      </div>

      <div className="site-section border-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-5">
              <img
                src="/assets/images/iscdrlogo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 pr-lg-5">
              {/* <h2 className="mb-4">Case Studies Title Here</h2> */}
              <p>
                <p>
                  The court is also considered as an international body of
                  legislation when necessary. The court has a moral personality
                  and has an independent internal system.
                </p>{" "}
                The lists of the Court's dispute settlement judges are divided
                into three categories:
                <ul>
                  <li>Category (A)</li>
                  <li>Category (B)</li>
                  <li>Category (C)</li>
                </ul>
                with the rules of procedure of the Court, the head of the
                chamber shall have the right to reject or accept request to join
                the Court's lists.
              </p>
              <p>
                The Court has a standing committee whose task is to draft laws
                and formulate and enact model International laws. The members of
                the Court have the status of arbitrator and mediator and...
              </p>
              <p>
                It should be noted that the International Supreme Court for
                dispute resolution assigns expertise and competencies in the
                field of mediation and arbitration, registered on their lists
                and schedules for the separation and decide on their own cases.
              </p>
              <p>
                The ISCDR deals with Arbitration, Mediation, Alternative means,
                and Artificial Intelligence Arbitration.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-5 order-lg-2">
              <img src="/assets/images/02.jpg" alt="" className="img-fluid " />
            </div>
            <div className="col-lg-6 pr-lg-5">
              <h2 className="mb-4">Advantages of memberships of the Court</h2>
              <p>
                -The possibility of assigning members to the peace processes
                that the Court is engaged in.
              </p>
              <p>
                -Participation in the development and formulation of model laws
                issued by the tomague of the law of the Court.
              </p>
              <p>
                -Membership shows the role model and the experience of the
                mediator and arbitrator in front of customers.
              </p>
              <p>
                -Opportunity to choose the member as an international arbitrator
                or mediator in disputes and its adoption within the
                international federations.{" "}
              </p>
              <p>
                -The membership also provides an opportunity for its bearer to
                become a certified judge on the list of judges of the Court.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <p>
          There are many Arbitral Courts in the world that resolve disputes by
          specialized arbitrators, as well as to provide solutions through
          alternative means such as mediation, negotiations and other...
        </p>
        <p>
          ISCDR is one of these international courts licensed and governed by
          the law of the United Kingdom Britain is headquarted in London and has
          many branches scattered arround the world. This Court which works at
          the national and international levels, is concerned with the
          settlement of disputes and the provision of a quick and a fair
          settlement which will help economic growth, stimulate international
          investment and the international trade movement. Peace and harmony are
          also achived in the international community. It's the most important
          goal of the world today, through a special body of experts judges and
          advisers comprising the Court.
        </p>
        <p className="pb-3">
          If the judiciary is the body authorized by a constitution and a law to
          settle people, it is not necessary that all issues be resolved by it.
          Other means are used to resolve and settle disputes among the parties
          to conflict themselves. Since ancient times, human society has known
          peaceful and friendly means of resolving disputes such as arbitration,
          mediation, negotiation, conciliation and reconciliation. These methods
          have been referred to international legal jurisprudence as alternative
          means of settling disputes, since the main means is the judiciary of
          the state. However, these methods are older and more ancient in the
          justice and state itself.
        </p>
      </div>

      <div className="container">
        <AboutTheCourt />
        <div className="card border-0 shadow mb-3">
          <div
            className="d-flex justify-content-between align-items-center p-2"
            onClick={() => openCollapse(0)}>
            <h3>Arbitration</h3>
            <i className={`bi bi-chevron-${!open[0] ? "down" : "up"}`}></i>
          </div>
          <Collapse in={open[0]}>
            <div className="px-3">
              <p>
                Article 37 of the hague convention of 1970 states that
                international arbitration aims to settle disputes among states
                by judges elected by them and on basis of respect for the right,
                and the recourse of arbitration presupposes a pledge to submit
                to the resulting solution on the basis of good faith.{" "}
              </p>
              <p>
                Arbitration is an agreement between two or more parties to
                resort to arbitration to settle all or some of the disputes that
                arose or many arise on the occasion of a certain legal
                relationship, whether spontaneous or spontaneous.{" "}
              </p>
              <p>
                The arbitration shall be instituonalized if it is carried out by
                an institution or a special arbitral tribunal or a Court as
                ISCDR.{" "}
              </p>

              <IscdrArbitration />
              <div className="py-3">
                <h3>ANNEX</h3>
                <div
                  className="card my-2 p-2 shadow border-0"
                  onClick={() => openCollapse(2)}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>
                      Model arbitration clause{" "}
                      <span className="text-primary">for contracts</span>
                    </h3>
                    <i
                      className={`bi bi-chevron-${open[2] ? "up" : "down"}`}
                      onClick={() => openCollapse(2)}></i>
                  </div>

                  <Collapse in={open[2]}>
                    <ol type="a">
                      Any dispute, controversy or claim arising out of or
                      relating to this contract, or the breach, termination or
                      invalidity thereof, shall be settled by arbitration in
                      accordance with the UNCITRAL Arbitration Rules. <br />
                      <i>Note. Parties should consider adding:</i>{" "}
                      <li>
                        The appointing authority shall be ... [name of
                        institution or person];
                      </li>
                      <li>
                        The number of arbitrators shall be ... [one or three];
                      </li>
                      <li>
                        The place of arbitration shall be ... [town and
                        country];
                      </li>
                      <li>
                        The language to be used in the arbitral proceedings
                        shall be ... .
                      </li>
                    </ol>
                  </Collapse>
                </div>
                <div
                  className="card my-2 p-2 shadow border-0"
                  onClick={() => openCollapse(3)}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>
                      Possible waiver{" "}
                      <span className="text-primary"> statement </span>
                    </h3>
                    <i
                      className={`bi bi-chevron-${open[3] ? "up" : "down"}`}
                      onClick={() => openCollapse(3)}></i>
                  </div>

                  <Collapse in={open[3]}>
                    <ol type="a">
                      <i>
                        Note. If the parties wish to exclude recourse against
                        the arbitral award that may be available under the
                        applicable law, they may consider adding a provision to
                        that effect as suggested below, considering, however,
                        that the effectiveness and conditions of such an
                        exclusion depend on the applicable law.
                        <br />
                        <strong>Waiver</strong>
                      </i>{" "}
                      <br />
                      The parties hereby waive their right to any form of
                      recourse against an award to any court or other competent
                      authority, insofar as such waiver can validly be made
                      under the applicable law.
                    </ol>
                  </Collapse>
                </div>
                <div
                  className="card my-2 p-2 shadow border-0"
                  onClick={() => openCollapse(4)}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>
                      Model statements of independence pursuant to{" "}
                      <span className="text-primary">
                        article 11 of the Rules{" "}
                      </span>
                    </h3>
                    <i
                      className={`bi bi-chevron-${open[4] ? "up" : "down"}`}
                      onClick={() => openCollapse(4)}></i>
                  </div>

                  <Collapse in={open[4]}>
                    <ul style={{ listStyle: "none" }}>
                      <i>No circumstances to disclose</i>{" "}
                      <li>
                        I am impartial and independent of each of the parties
                        and intend to remain so. To the best of my knowledge,
                        there are no circumstances, past or present, likely to
                        give rise to justifiable doubts as to my impartiality or
                        independence. I shall promptly notify the parties and
                        the other arbitrators of 30 any such circumstances that
                        may subsequently come to my attention during this
                        arbitration.
                      </li>
                      <i>Circumstances to disclose</i>{" "}
                      <li>
                        I am impartial and independent of each of the parties
                        and intend to remain so. Attached is a statement made
                        pursuant to article 11 of the UNCITRAL Arbitration Rules
                        of (a) my past and present professional, business and
                        other relationships with the parties and (b) any other
                        relevant circumstances. [Include statement.] I confirm
                        that those circumstances do not affect my independence
                        and impartiality. I shall promptly notify the parties
                        and the other arbitrators of any such further
                        relationships or circumstances that may subsequently
                        come to my attention during this arbitration.
                      </li>
                      <i>
                        Note. Any party may consider requesting from the
                        arbitrator the following addition to the statement of
                        independence:
                      </i>{" "}
                      <br />I confirm, on the basis of the information presently
                      available to me, that I can devote the time necessary to
                      conduct this arbitration diligently, efficiently and in
                      accordance with the time limits in the Rules.
                    </ul>
                  </Collapse>
                </div>
              </div>
              <div className="pb-3">
                <h3>Mauritian International Arbitration</h3>
                <div
                  className="card my-2 p-2 shadow border-0"
                  onClick={() => openCollapse(6)}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>
                      Procedure for Requests under the{" "}
                      <span className="text-primary">
                        Mauritian International Arbitration Act 2008
                      </span>
                    </h3>
                    <i
                      className={`bi bi-chevron-${open[6] ? "up" : "down"}`}
                      onClick={() => openCollapse(6)}></i>
                  </div>

                  <Collapse in={open[6]}>
                    <ol>
                      <i>
                        Requests under the Mauritian International Arbitration
                        Act 2008 should be directed to the Secretary-General
                        care of the PCA Mauritius Office The request should be
                        accompanied by:{" "}
                      </i>{" "}
                      <li>
                        A copy of the arbitration clause, agreement or decision
                        by the arbitral tribunal in relation to the seat of the
                        arbitration establishing the applicability of the
                        Mauritian International Arbitration Act 2008;
                      </li>
                      <li>
                        An indication of the section of the Mauritian
                        International Arbitration Act 2008 under which the
                        request is being made and of the measure requested;
                      </li>
                      <li>
                        {" "}
                        A copy of the Notice of Arbitration or equivalent
                        document commencing the arbitration served upon the
                        respondent, as well as an indication of the datec of
                        such service;
                      </li>
                      <li>
                        An indication of the names and nationalities of the
                        parties;
                      </li>
                      <li>
                        An indication of the names and nationalities of the
                        arbitrators already appointed, if any;
                      </li>
                      <li>
                        In the event of a request relating to the constitution
                        of the tribunal, an indication of the names of any
                        institutions or persons that the parties had considered
                        selecting as appointing authority but which have had
                        been considered rejected, or, as the case may be, the
                        names of any persons that parties had considered
                        selectiong as arbitrators but who have been rejected;
                      </li>
                      <li>
                        In the event of a request to decide a challenge to an
                        arbitraator pursuant to of the Act, a copy of the
                        statement of the reasons for the challenge that have
                        beensubmitted to the arbitral tribunal, of any comments
                        that may have been submitted to the arbitral tribunal by
                        the opposing party, and of the arbitral tribunal's
                        decision on the challenge; and
                      </li>
                      <li>
                        A power of attorney evidencing the authority of the
                        person making the request. Following receiot of the
                        request, the PCA may seek additional documention as it
                        deems necessary to act on the request.
                      </li>
                      For the time being, no fee is payable to the PCA for the
                      consideration by the Secretary-General of request under
                      the Mauritian International Arbitration Act 2008.
                    </ol>
                  </Collapse>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="card border-0 shadow mb-3">
          <div
            className="d-flex justify-content-between align-items-center p-2"
            onClick={() => openCollapse(1)}>
            <h3>Mediation</h3>
            <i className={`bi bi-chevron-${!open[1] ? "down" : "up"}`}></i>
          </div>
          <Collapse in={open[1]}>
            <div className="px-3">
              <p>
                Mark Silko says that the Mediation is the process by which a
                third party helps two or more people to be exposed to a
                situation that creates a problem. <br /> The dispute shall be
                mediated by a third party acceptable to the parties to reach a
                decision in order to assist the parties to the conflict to reach
                a mutually acceptable settlement. <br />
                When the parties agree in writing to refer disputes to mediation
                in accordance with the rules of international Mediation or when
                they formulate a text that is subject to existing or future
                international disputes for mediation or conciliation without
                specifying specific rules, these amended rules in force at the
                date of the dispute shall be considered an integral part of
                their agreement. he mutual agreement between them, the amendment
                of any part of these rules, including without limiting the
                concert to conduct Mediation by phone or other electronic or
                technical rules. Thus, the International supreme court for
                dispute resolution operates under the UNCITRAI del a
                conciliation and mediation with its guide to enactment and use
                on for 2002.
                <br /> And that through work:
                <ul>
                  <li>Agreement of the parties.</li>
                  <li>Start mediation.</li>
                  <li>The acting</li>
                  <li>Set the Mediator.</li>
                  <li>Broker neutrality and duty of mediator.</li>
                  <li>Duties and responsibilities of the mediator.</li>
                  <li>Responsibilities of parties.</li>
                  <li>Privacy -Confidentiality.</li>
                  <li>No down grade record.</li>
                  <li>End of mediation.</li>
                  <li>Exemption from Liability.</li>
                  <li>Interpretation and application of procedures.</li>
                  <li>Deposits.</li>
                  <li>Expenses.</li>
                  <li>Cost of Mediation.</li>
                </ul>
              </p>
            </div>
          </Collapse>
        </div>
        <div className="card border-0 shadow mb-3">
          <div
            className="d-flex justify-content-between align-items-center p-2"
            onClick={() => openCollapse(5)}>
            <h3>Internal Affairs</h3>
            <i className={`bi bi-chevron-${!open[5] ? "down" : "up"}`}></i>
          </div>
          <Collapse in={open[5]}>
            <div className="px-3">
              <h3 className="text-center">
                Rules of Procedare of the International Supreme Court for the
                Settlement of Disputes-London
              </h3>
              <p className="text-justify">
                <strong>Clause one:The articles of association</strong>
                <br /> The International Supreme Court for Dispute Resolution -
                London is a private international arbitral entity based in
                London - the United Court is registered under the numbers APO
                13566880 and APO 11738311 and has several branches affiliated to
                it in many coutries of the world.
                <br />
                <strong> Clause two: The framework of the court's work.</strong>
                <br />
                The framework of the court's work. The court resolves lidlvidual
                and collective ocal and internalional disputes through
                arbitration., mediation negotiations, diplomacy and other
                amicable means to resolve disputes, whether they result from
                contractual or non- contractual relations.
                <br />
                <strong>Clause three: Objective of the court:</strong>
                <br /> Resolving local and international disputes by peaceful
                means Attracting businessmen and investment companies and
                converting them towards adopting these peaceful means instead of
                resorling to the ordinary judiciary due to the speed, ease of
                procedures and confidentiality Developing the culture of
                arbitration, mediation and others among individuals, whether
                natural or legal, and spreading the culture of arbitration,
                mediation and alternative means for resolving disputes
                <br />
                <strong>Clause four: Court Forms:</strong>
                <br />
                Court Forms: The court, as well as each of its branches, is
                composed of the president, in addition to three main bodies
                <br />
                <u>The President:</u>
                <br /> The President of the Court appoints the heads of the
                circuits of the six countries and tretr representations, which
                are the head of the North American branch, the South American
                branch, the heed of the Europe branch, the Asian branch, and the
                head of the Middle East, Africa and Mediterranean branch.
                <u>Judges'Form:</u> <br />
                The lists of dispute setlement ludges In the court are divlded
                into three caleaories:{" "}
                <ul style={{ listStyle: "none" }}>
                  <li>Class A</li>
                  <li>Class B</li>
                  <li>Class C</li>
                </ul>
                They arbitrate in the arbitration cases, and the president of
                the court has the right to accept or reject the request to join
                the court lists.
                <br />
                <u>Scientiflc Board:</u>
                <br />
                It consists of a number of doctors, academics, specialists in
                all arbitratlon and mediation cases, and ciplomats They also
                suggest appropriate matters to monitor the disciplinary
                procedures and their basis for arbitration.
                <br />
                <u>Administrative Board</u>
                <br />
                Which includes a number of employees to run the work of the
                office court(calbash)in the pen, offices, secretairies and
                others. Their number shall be determined according to the need
                for them and the extent of the court breadth and scope of work.
                <br />
                They are appointed by the branch manager after the approval of
                the head of the department to which the cours in geographical
                addition to scope these belongs. In addition to these board, the
                Court has a permanent committee tasked with drafing laws and
                enacting mode international rules.
                <br />
                <u>Mediators, negotiators and diplomats</u>
                <br />
                They are a group of mediators prepared i advande t cary out
                mediation and negotiations who are highly knowedgeable and
                experienced. They may be among the cour's judges or be granted,
                and mediators and negotiators can be delegated from outside the
                cour's framework and staff.
                <br />
                <ul>
                  The court also has many other bodies and members, the most
                  important of which are, for example:
                  <li>International Relations Officer.</li>
                  <li>Media official.</li>
                  <li>
                    Responsible for electronic programming and other evices
                    necessary to cary out the work cf the court.{" "}
                  </li>
                </ul>
                <strong>Clause five: Court divisions:</strong>
                <br />
                <ul>
                  The court includes an obligation as well as each of its
                  branches.
                  <li>
                    One or more permanent courtrooms (to decide arbitration
                    cases)
                  </li>
                  <li>Conference and lecture hall.</li>
                  <li>Legal library.</li>
                  <li>Archive and save files.</li>
                  <li>
                    A permanent office for the head of the department to which
                    the court or the branch belongs geographically, and an Hall
                    for mediation, negotiations or others. office for the branch
                    manager.{" "}
                  </li>
                  <li>
                    Staff offices (Other offices can be created as needed,
                    according to work necessities).
                  </li>
                </ul>
                <strong>Clause six: Affillation with the Court:</strong>
                <br />
                The door to affiation with the court is open to those who:
                <br />
                He has qualifications in the fleld of arbitration, mediation,
                negotiation, and peaceful and diplomatic means of resolving
                disputes. <br />
                For those who participate in the programs and courses prepared
                and organized by the court.To be enjoying his/her civil rights
                and good behavior. The affliation takes place according to
                specific conditions and after submitting dataéto be announced on
                the court's website as in allits offices.
                <br />
                <strong>
                  Clause seven: The parties that the court cooperates with: (for
                  example, but not limited to).
                </strong>
                he Court cooperates with many other courts and arbitration
                centers. Also, with a number of universities, academies,
                governmental and non-governmental bodies and organizations, with
                trade unions, federations, chambers of commerce and industry,
                and many scientific and professional institutions, among
                others...
                <br />
                <strong>
                  Clause eight: Issuance of Memberships and Certificates:
                </strong>
                <br />
                In addition to arbitration, the court conducts mediation,
                negotiations, and others. Issuing membership cards to judges,
                advisors, arbitrators and new members for a renewable period t
                also issues cerificates of attendance and participation in the
                workshops it holds, its sclentific sessions, and its
                conferences, which it holds individually or in cooperation with
                other authorities and references.
                <br />
                <strong>
                  Clause nine: Meetings, Decisions and Voting in Court:
                </strong>
                <br />
                The Court holds an ordinary and obligatory meeting once a year
                in the presence of the president of the court as well as all the
                heads of departments. The Court also holds periodic meetings at
                the invitation of its president wheneverthe need arises, and the
                meetings are valid if they are held in the presence pf the
                absolute majority of members that comprise them, and it takes
                its decisions by the relative majority of the member present.
                <br />
                <strong>Clause ten: </strong>
                <br />
                The Court has the right to appoint and name the names of its
                employees and representatives with the names it wants especially
                members, arbitrators, advisors, judges, and to issue memberships
                and certificates in their names and their designations by the
                Internatione! Supreme Court for Dispute Resolution - London to
                facilitate the work of the Court.
                <br />
                <strong>Clause eleven: </strong>
                <br />
                The court has te right to give warnings to its employees and
                representatives, verbally, in writing. or through all electronic
                means.
                <br />
                The court has the right to any employee or representative after
                receiving two warnings to dismiss him permanenty if he violates
                the rules of dealing, failure to preserve and defend the
                reputation of the court, insulting it morally and esthically,
                and not respecting the work progress of the court and its
                general manager.
                <br />
                <strong>Clause twelve: </strong>
                <br />
                Court presidents, judges, and all members of the court, of all
                ranks, are bound by the rules of the court and their
                application, as well as the implementation of all decisions and
                circulars issued by the court from the date of their assuance
                and publication, under the penalty of disciplinary
                accountability for violators.
              </p>
              <p className="text-justify">
                <strong>Clause 13:</strong>
                <br />
                <i>
                  Employees and workers of the International Supreme Court for
                  Disputes Resolution - London, holders of cards and
                  certificates, representatives of countries under protocols or
                  others in all countries in the event of any dispute arising,
                  it is resolved by the court ISCDR through mediation or
                  arbitration in accordance with justice and fairness.
                </i>
                <br />
                <strong>Modal Clause</strong>
                <br />
                <i>
                  Under this contract, the parties have agreed on any dispute
                  arising in connection with this contract first related to it,
                  whether related to its implementation, non-performance,
                  validity, invalidity, rescission or termination, and it shall
                  be finally resolved in accordance with the arbitration rules
                  set by the UNCITRAL Commission year 2013 or ad hoc, provided
                  that the arbitrator The power to issue temporary or
                  precautionary decisions or orders regarding a dispute
                  presented to it by an arbitrator or several arbitrators.
                </i>
                <br />
                <i>
                  Arbiration rulings: are final, enforceable and not subject to
                  appeal in any way of appeal and appeal, absolute and finding
                  on its paries. The arbitrators are also appointed by the
                  International Supreme Court for Dispute Resolution London
                  ISCDR - from its arbitrators list it has.
                </i>
              </p>
            </div>
          </Collapse>
        </div>
      </div>

      {/* <div className="section-2">
        <div className="container">
          <div className="row no-gutters align-items-stretch align-items-center">
            <div className="col-lg-4">
              <div
                className="service-1 first h-100"
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-balance"></span>
                  </span>
                  <h2>Business Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_2.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-law"></span>
                  </span>
                  <h2>Criminal Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_3.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-courthouse"></span>
                  </span>
                  <h2>Tax Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="service-1 first h-100"
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-balance"></span>
                  </span>
                  <h2>Business Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_2.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-law"></span>
                  </span>
                  <h2>Criminal Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_3.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-courthouse"></span>
                  </span>
                  <h2>Tax Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
