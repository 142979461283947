import React from "react";
import { Collapse } from "react-bootstrap";

export default function IscdrArbitration() {
  const [open, setOpen] = React.useState<boolean[]>([]);
  const openCollapse = (index: number) => {
    let temp = [...open];
    temp[index] = !temp[index];
    setOpen(temp);
  };
  return (
    <>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(0)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Scope of <span className="text-primary">application</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[0] ? "up" : "down"}`}
            onClick={() => openCollapse(0)}></i>
        </div>

        <Collapse in={open[0]}>
          <ol>
            <strong>Article 1</strong>{" "}
            <li>
              Where parties have agreed that disputes between them in respect of
              a defined legal relationship, whether contractual or not, shall be
              referred to arbitration under the UNCITRAL Arbitration Rules, then
              such disputes shall be settled in accordance with these Rules
              subject to such modification as the parties may agree.
            </li>
            <li>
              The parties to an arbitration agreement concluded after 15 August
              2010 shall be presumed to have referred to the Rules in effect on
              the date of commencement of the arbitration, unless the parties
              have agreed to apply a particular version of the Rules. That
              presumption does not apply where the arbitration agreement has
              been concluded by accepting after 15 August 2010 an offer made
              before that date.
            </li>
            <li>
              These Rules shall govern the arbitration except that where any of
              these Rules is in conflict with a provision of the law applicable
              to the arbitration from which the parties cannot derogate, that
              provision shall prevail.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(1)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Notice and calculation of{" "}
            <span className="text-primary">periods of time</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[1] ? "up" : "down"}`}
            onClick={() => openCollapse(1)}></i>
        </div>

        <Collapse in={open[1]}>
          <ol>
            <strong>Article 2</strong>
            <li>
              A notice, including a notification, communication or proposal, may
              be transmitted by any means of communication that provides or
              allows for a record of its transmission.
            </li>
            <li>
              . If an address has been designated by a party specifically for
              this purpose or authorized by the arbitral tribunal, any notice
              shall be delivered to that party at that address, and if so
              delivered shall be deemed to have been received. Delivery by
              electronic means such as facsimile or e-mail may only be made to
              an address so designated or authorized.
            </li>
            <li>
              In the absence of such designation or authorization, a notice is:
              <br />
              (a) Received if it is physically delivered to the addressee; or
              <br />
              (b) Deemed to have been received if it is delivered at the place
              of business, habitual residence or mailing address of the
              addressee.
            </li>
            <li>
              If, after reasonable efforts, delivery cannot be effected in
              accordance with paragraphs 2 or 3, a notice is deemed to have been
              received if it is sent to the addressee’s last-known place of
              business, habitual residence or mailing address by registered
              letter or any other means that provides a record of delivery or of
              attempted delivery.{" "}
            </li>
            <li>
              A notice shall be deemed to have been received on the day it is
              delivered in accordance with paragraphs 2, 3 or 4, or attempted to
              be delivered in accordance with paragraph 4. A notice transmitted
              by electronic means is deemed to have been received on the day it
              is sent, except that a notice of arbitration so transmitted is
              only deemed to have been received on the day when it reaches the
              addressee’s electronic address.
            </li>
            <li>
              For the purpose of calculating a period of time under these Rules,
              such period shall begin to run on the day following the day when a
              notice is received. If the last day of such period is an official
              holiday or a non-business day at the residence or place of
              business of the addressee, the period is extended until the first
              business day which follows. Official holidays or nonbusiness days
              occurring during the running of the period of time are included in
              calculating the period.{" "}
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(2)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Notice of <span className="text-primary">arbitration</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[2] ? "up" : "down"}`}
            onClick={() => openCollapse(2)}></i>
        </div>

        <Collapse in={open[2]}>
          <ol>
            <strong>Article 3</strong>{" "}
            <li>
              The party or parties initiating recourse to arbitration
              (hereinafter called the “claimant”) shall communicate to the other
              party or parties (hereinafter called the “respondent”) a notice of
              arbitration.
            </li>
            <li>
              Arbitral proceedings shall be deemed to commence on the date on
              which the notice of arbitration is received by the respondent.
            </li>
            <li>
              The notice of arbitration shall include the following:
              <br />
              (a) A demand that the dispute be referred to arbitration;
              <br />
              (b) The names and contact details of the parties;
              <br />
              (c) Identification of the arbitration agreement that is invoked;
              <br />
              (d) Identification of any contract or other legal instrument out
              of or in relation to which the dispute arises or, in the absence
              of such contract or instrument, a brief description of the
              relevant relationship;
              <br />
              (e) A brief description of the claim and an indication of the
              amount involved, if any;
              <br />
              (f) The relief or remedy sought;
              <br />
              (g) A proposal as to the number of arbitrators, language and place
              of arbitration, if the parties have not previously agreed thereon.
            </li>
            <li>
              The notice of arbitration may also include:
              <br />
              (a) A proposal for the designation of an appointing authority
              referred to in article 6, paragraph 1;
              <br />
              (b) A proposal for the appointment of a sole arbitrator referred
              to in article 8, paragraph 1;
              <br />
              (c) Notification of the appointment of an arbitrator referred to
              in article 9 or 10.{" "}
            </li>
            <li>
              The constitution of the arbitral tribunal shall not be hindered by
              any controversy with respect to the sufficiency of the notice of
              arbitration, which shall be finally resolved by the arbitral
              tribunal.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(3)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Response to the notice of{" "}
            <span className="text-primary">arbitration</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[3] ? "up" : "down"}`}
            onClick={() => openCollapse(3)}></i>
        </div>

        <Collapse in={open[3]}>
          <ol>
            <strong>Article 4</strong>{" "}
            <li>
              Within 30 days of the receipt of the notice of arbitration, the
              respondent shall communicate to the claimant a response to the
              notice of arbitration, which shall include:
              <br />
              (a) The name and contact details of each respondent;
              <br />
              (b) A response to the information set forth in the notice of
              arbitration, pursuant to article 3, paragraphs 3 (c) to (g).
            </li>
            <li>
              The response to the notice of arbitration may also include: <br />
              Any plea that an arbitral tribunal to be constituted under these
              Rules lacks jurisdiction; <br />
              (b) A proposal for the designation of an appointing authority
              referred to in article 6, paragraph 1; <br />
              (c) A proposal for the appointment of a sole arbitrator referred
              to in article 8, paragraph 1; <br />
              (d) Notification of the appointment of an arbitrator referred to
              in article 9 or 10; <br />
              (e) A brief description of counterclaims or claims for the purpose
              of a set-off, if any, including where relevant, an indication of
              the amounts involved, and the relief or remedy sought; <br />
              (f) A notice of arbitration in accordance with article 3 in case
              the respondent formulates a claim against a party to the
              arbitration agreement other than the claimant
            </li>
            <li>
              The constitution of the arbitral tribunal shall not be hindered by
              any controversy with respect to the respondent’s failure to
              communicate a response to the notice of arbitration, or an
              incomplete or late response to the notice of arbitration, which
              shall be finally resolved by the arbitral tribunal.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(4)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Representation and <span className="text-primary">assistance</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[4] ? "up" : "down"}`}
            onClick={() => openCollapse(4)}></i>
        </div>

        <Collapse in={open[4]}>
          <ol>
            <strong>Article 5</strong>
            <br />
            Each party may be represented or assisted by persons chosen by it.
            The names and addresses of such persons must be communicated to all
            parties and to the arbitral tribunal. Such communication must
            specify whether the appointment is being made for purposes of
            representation or assistance. Where a person is to act as a
            representative of a party, the arbitral tribunal, on its own
            initiative or at the request of any party, may at any time require
            proof of authority granted to the representative in such a form as
            the arbitral tribunal may determine.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(5)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Designating and appointing{" "}
            <span className="text-primary">authorities</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[5] ? "up" : "down"}`}
            onClick={() => openCollapse(5)}></i>
        </div>

        <Collapse in={open[5]}>
          <ol>
            <strong>Article 6</strong>{" "}
            <li>
              Unless the parties have already agreed on the choice of an
              appointing authority, a party may at any time propose the name or
              names of one or more institutions or persons, including the
              Secretary-General of the Permanent Court of Arbitration at The
              Hague (hereinafter called the “PCA”), one of whom would serve as
              appointing authority.
            </li>
            <li>
              If all parties have not agreed on the choice of an appointing
              authority within 30 days after a proposal made in accordance with
              paragraph 1 has been received by all other parties, any party may
              request the Secretary-General of the PCA to designate the
              appointing authority.
            </li>
            <li>
              Where these Rules provide for a period of time within which a
              party must refer a matter to an appointing authority and no
              appointing authority has been agreed on or designated, the period
              is suspended from the date on which a party initiates the
              procedure for agreeing on or designating an appointing authority
              until the date of such agreement or designation.
            </li>
            <li>
              Except as referred to in article 41, paragraph 4, if the
              appointing authority refuses to act, or if it fails to appoint an
              arbitrator within 30 days after it receives a party’s request to
              do so, fails to act within any other period provided by these
              Rules, or fails to decide on a challenge to an arbitrator within a
              reasonable time after receiving a party’s request to do so, any
              party may request the SecretaryGeneral of the PCA to designate a
              substitute appointing authority.
            </li>
            <li>
              Except as referred to in article 41, paragraph 4, if the
              appointing authority refuses to act, or if it fails to appoint an
              arbitrator within 30 days after it receives a party’s request to
              do so, fails to act within any other period provided by these
              Rules, or fails to decide on a challenge to an arbitrator within a
              reasonable time after receiving a party’s request to do so, any
              party may request the SecretaryGeneral of the PCA to designate a
              substitute appointing authority.
            </li>
            <li>
              When the appointing authority is requested to appoint an
              arbitrator pursuant to articles 8, 9, 10 or 14, the party making
              the request shall send to the appointing authority copies of the
              notice of arbitration and, if it exists, any response to the
              notice of arbitration.{" "}
            </li>
            <li>
              The appointing authority shall have regard to such considerations
              as are likely to secure the appointment of an independent and
              impartial arbitrator and shall take into account the advisability
              of appointing an arbitrator of a nationality other than the
              nationalities of the parties
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(6)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Number of <span className="text-primary">arbitrators</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[6] ? "up" : "down"}`}
            onClick={() => openCollapse(6)}></i>
        </div>

        <Collapse in={open[6]}>
          <ol>
            <strong>Article 7</strong>{" "}
            <li>
              If the parties have not previously agreed on the number of
              arbitrators, and if within 30 days after the receipt by the
              respondent of the notice of arbitration the parties have not
              agreed that there shall be only one arbitrator, three arbitrators
              shall be appointed.
            </li>
            <li>
              Notwithstanding paragraph 1, if no other parties have responded to
              a party’s proposal to appoint a sole arbitrator within the time
              limit provided for in paragraph 1 and the party or parties
              concerned have failed to appoint a second arbitrator in accordance
              with article 9 or 10, the appointing authority may, at the request
              of a party, appoint a sole arbitrator pursuant to the procedure
              provided for in article 8, paragraph 2, if it determines that, in
              view of the circumstances of the case, this is more appropriate.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(7)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Appointment of <span className="text-primary">arbitrators</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[7] ? "up" : "down"}`}
            onClick={() => openCollapse(7)}></i>
        </div>

        <Collapse in={open[7]}>
          <div>
            <ol>
              <strong>Article 8</strong>{" "}
              <li>
                If the parties have agreed that a sole arbitrator is to be
                appointed and if within 30 days after receipt by all other
                parties of a proposal for the appointment of a sole arbitrator
                the parties have not reached agreement thereon, a sole
                arbitrator shall, at the request of a party, be appointed by the
                appointing authority
              </li>
              <li>
                The appointing authority shall appoint the sole arbitrator as
                promptly as possible. In making the appointment, the appointing
                authority shall use the following list-procedure, unless the
                parties agree that the list-procedure should not be used or
                unless the appointing authority determines in its discretion
                that the use of the list-procedure is not appropriate for the
                case:
                <ol type="a">
                  <li>
                    The appointing authority shall communicate to each of the
                    parties an identical list containing at least three names;
                  </li>
                  <li>
                    Within 15 days after the receipt of this list, each party
                    may return the list to the appointing authority after having
                    deleted the name or names to which it objects and numbered
                    the remaining names on the list in the order of its
                    preference;
                  </li>
                  <li>
                    After the expiration of the above period of time the
                    appointing authority shall appoint the sole arbitrator from
                    among the names approved on the lists returned to it and in
                    accordance with the order of preference indicated by the
                    parties;
                  </li>
                  <li>
                    These Rules shall govern the arbitration except that where
                    any of these Rules is in conflict with a provision of the
                    law applicable to the arbitration from which the parties
                    cannot derogate, that provision shall prevail.
                  </li>
                </ol>
              </li>
            </ol>
            <ol>
              <strong>Article 9</strong>{" "}
              <li>
                If three arbitrators are to be appointed, each party shall
                appoint one arbitrator. The two arbitrators thus appointed shall
                choose the third arbitrator who will act as the presiding
                arbitrator of the arbitral tribunal.
              </li>
              <li>
                If within 30 days after the receipt of a party’s notification of
                the appointment of an arbitrator the other party has not
                notified the first party of the arbitrator it has appointed, the
                first party may request the appointing authority to appoint the
                second arbitrator.
              </li>
              <li>
                If within 30 days after the appointment of the second arbitrator
                the two arbitrators have not agreed on the choice of the
                presiding arbitrator, the presiding arbitrator shall be
                appointed by the appointing authority in the same way as a sole
                arbitrator would be appointed under article 8.
              </li>
            </ol>
            <ol>
              <strong>Article 10</strong>{" "}
              <li>
                For the purposes of article 9, paragraph 1, where three
                arbitrators are to be appointed and there are multiple parties
                as claimant or as respondent, unless the parties have agreed to
                another method of appointment of arbitrators, the multiple
                parties jointly, whether as claimant or as respondent, shall
                appoint an arbitrator.
              </li>
              <li>
                If the parties have agreed that the arbitral tribunal is to be
                composed of a number of arbitrators other than one or three, the
                arbitrators shall be appointed according to the method agreed
                upon by the parties.
              </li>
              <li>
                In the event of any failure to constitute the arbitral tribunal
                under these Rules, the appointing authority shall, at the
                request of any party, constitute the arbitral tribunal and, in
                doing so, may revoke any appointment already made and appoint or
                reappoint each of the arbitrators and designate one of them as
                the presiding arbitrator
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(8)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Disclosures by and challenge{" "}
            <span className="text-primary">of arbitrators</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[8] ? "up" : "down"}`}
            onClick={() => openCollapse(8)}></i>
        </div>

        <Collapse in={open[8]}>
          <div>
            <ol>
              <strong>Article 11</strong> <br /> When a person is approached in
              connection with his or her possible appointment as an arbitrator,
              he or she shall disclose any circumstances likely to give rise to
              justifiable doubts as to his or her impartiality or independence.
              An arbitrator, from the time of his or her appointment and
              throughout the arbitral proceedings, shall without delay disclose
              any such circumstances to the parties and the other arbitrators
              unless they have already been informed by him or her of these
              circumstances.
            </ol>
            <ol>
              <strong>Article 12</strong>{" "}
              <li>
                Any arbitrator may be challenged if circumstances exist that
                give rise to justifiable doubts as to the arbitrator’s
                impartiality or independence.
              </li>
              <li>
                A party may challenge the arbitrator appointed by it only for
                reasons of which it becomes aware after the appointment has been
                made.
              </li>
              <li>
                In the event that an arbitrator fails to act or in the event of
                the de jure or de facto impossibility of his or her performing
                his or her functions, the procedure in respect of the challenge
                of an arbitrator as provided in article 13 shall apply.
              </li>
            </ol>
            <ol>
              <strong>Article 13</strong>{" "}
              <li>
                A party that intends to challenge an arbitrator shall send
                notice of its challenge within 15 days after it has been
                notified of the appointment of the challenged arbitrator, or
                within 15 days after the circumstances mentioned in articles 11
                and 12 became known to that party.
              </li>
              <li>
                The notice of challenge shall be communicated to all other
                parties, to the arbitrator who is challenged and to the other
                arbitrators. The notice of challenge shall state the reasons for
                the challenge.{" "}
              </li>
              <li>
                When an arbitrator has been challenged by a party, all parties
                may agree to the challenge. The arbitrator may also, after the
                challenge, withdraw from his or her office. In neither case does
                this imply acceptance of the validity of the grounds for the
                challenge.
              </li>
              <li>
                If, within 15 days from the date of the notice of challenge, all
                parties do not agree to the challenge or the challenged
                arbitrator does not withdraw, the party making the challenge may
                elect to pursue it. In that case, within 30 days from the date
                of the notice of challenge, it shall seek a decision on the
                challenge by the appointing authority.
              </li>
            </ol>
          </div>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(9)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Replacement of <span className="text-primary">an arbitrator</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[9] ? "up" : "down"}`}
            onClick={() => openCollapse(9)}></i>
        </div>

        <Collapse in={open[9]}>
          <ol>
            <strong>Article 14</strong>{" "}
            <li>
              Subject to paragraph 2, in any event where an arbitrator has to be
              replaced during the course of the arbitral proceedings, a
              substitute arbitrator shall be appointed or chosen pursuant to the
              procedure provided for in articles 8 to 11 that was applicable to
              the appointment or choice of the arbitrator being replaced. This
              procedure shall apply even if during the process of appointing the
              arbitrator to be replaced, a party had failed to exercise its
              right to appoint or to participate in the appointment.
            </li>
            <li>
              If, at the request of a party, the appointing authority determines
              that, in view of the exceptional circumstances of the case, it
              would be justified for a party to be deprived of its right to
              appoint a substitute arbitrator, the appointing authority may,
              after giving an opportunity to the parties and the remaining
              arbitrators to express their views: (a) appoint the substitute
              arbitrator; or (b) after the closure of the hearings, authorize
              the other arbitrators to proceed with the arbitration and make any
              decision or award.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(10)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Repetition of hearings in the event of{" "}
            <span className="text-primary">
              the replacement of an arbitrator
            </span>
          </h3>
          <i
            className={`bi bi-chevron-${open[10] ? "up" : "down"}`}
            onClick={() => openCollapse(10)}></i>
        </div>

        <Collapse in={open[10]}>
          <ol>
            <strong>Article 15</strong> <br />
            If an arbitrator is replaced, the proceedings shall resume at the
            stage where the arbitrator who was replaced ceased to perform his or
            her functions, unless the arbitral tribunal decides otherwise.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(11)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Exclusion of <span className="text-primary">liability</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[11] ? "up" : "down"}`}
            onClick={() => openCollapse(11)}></i>
        </div>

        <Collapse in={open[11]}>
          <ol>
            <strong>Article 16</strong> <br />
            Save for intentional wrongdoing, the parties waive, to the fullest
            extent permitted under the applicable law, any claim against the
            arbitrators, the appointing authority and any person appointed by
            the arbitral tribunal based on any act or omission in connection
            with the arbitration.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(12)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Scope of <span className="text-primary">application</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[12] ? "up" : "down"}`}
            onClick={() => openCollapse(12)}></i>
        </div>

        <Collapse in={open[12]}>
          <ol>
            <strong>Article 17</strong>{" "}
            <li>
              Subject to these Rules, the arbitral tribunal may conduct the
              arbitration in such manner as it considers appropriate, provided
              that the parties are treated with equality and that at an
              appropriate stage of the proceedings each party is given a
              reasonable opportunity of presenting its case. The arbitral
              tribunal, in exercising its discretion, shall conduct the
              proceedings so as to avoid unnecessary delay and expense and to
              provide a fair and efficient process for resolving the parties’
              dispute.
            </li>
            <li>
              As soon as practicable after its constitution and after inviting
              the parties to express their views, the arbitral tribunal shall
              establish the provisional timetable of the arbitration. The
              arbitral tribunal may, at any time, after inviting the parties to
              express their views, extend or abridge any period of time
              prescribed under these Rules or agreed by the parties.
            </li>
            <li>
              If at an appropriate stage of the proceedings any party so
              requests, the arbitral tribunal shall hold hearings for the
              presentation of evidence by witnesses, including expert witnesses,
              or for oral argument. In the absence of such a request, the
              arbitral tribunal shall decide whether to hold such hearings or
              whether the proceedings shall be conducted on the basis of
              documents and other materials.{" "}
            </li>
            <li>
              All communications to the arbitral tribunal by one party shall be
              communicated by that party to all other parties. Such
              communications shall be made at the same time, except as otherwise
              permitted by the arbitral tribunal if it may do so under
              applicable law.
            </li>
            <li>
              The arbitral tribunal may, at the request of any party, allow one
              or more third persons to be joined in the arbitration as a party
              provided such person is a party to the arbitration agreement,
              unless the arbitral tribunal finds, after giving all parties,
              including the person or persons to be joined, the opportunity to
              be heard, that joinder should not be permitted because of
              prejudice to any of those parties. The arbitral tribunal may make
              a single award or several awards in respect of all parties so
              involved in the arbitration.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(13)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Place of <span className="text-primary">arbitration</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[13] ? "up" : "down"}`}
            onClick={() => openCollapse(13)}></i>
        </div>

        <Collapse in={open[13]}>
          <ol>
            <strong>Article 18</strong>{" "}
            <li>
              If the parties have not previously agreed on the place of
              arbitration, the place of arbitration shall be determined by the
              arbitral tribunal having regard to the circumstances of the case.
              The award shall be deemed to have been made at the place of
              arbitration.
            </li>
            <li>
              The arbitral tribunal may meet at any location it considers
              appropriate for deliberations. Unless otherwise agreed by the
              parties, the arbitral tribunal may also meet at any location it
              considers appropriate for any other purpose, including hearings.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(14)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Language<span className="text-primary"></span>
          </h3>
          <i
            className={`bi bi-chevron-${open[14] ? "up" : "down"}`}
            onClick={() => openCollapse(14)}></i>
        </div>

        <Collapse in={open[14]}>
          <ol>
            <strong>Article 19</strong>{" "}
            <li>
              Subject to an agreement by the parties, the arbitral tribunal
              shall, promptly after its appointment, determine the language or
              languages to be used in the proceedings. This determination shall
              apply to the statement of claim, the statement of defence, and any
              further written statements and, if oral hearings take place, to
              the language or languages to be used in such hearings.
            </li>
            <li>
              The arbitral tribunal may order that any documents annexed to the
              statement of claim or statement of defence, and any supplementary
              documents or exhibits submitted in the course of the proceedings,
              delivered in their original language, shall be accompanied by a
              translation into the language or languages agreed upon by the
              parties or determined by the arbitral tribunal.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(15)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Statement of <span className="text-primary">claim</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[15] ? "up" : "down"}`}
            onClick={() => openCollapse(15)}></i>
        </div>

        <Collapse in={open[15]}>
          <ol>
            <strong>Article 20</strong>{" "}
            <li>
              The claimant shall communicate its statement of claim in writing
              to the respondent and to each of the arbitrators within a period
              of time to be determined by the arbitral tribunal. The claimant
              may elect to treat its notice of arbitration referred to in
              article 3 as a statement of claim, provided that the notice of
              arbitration also complies with the requirements of paragraphs 2 to
              4 of this article.
            </li>
            <li>
              The statement of claim shall include the following particulars:
              <ol type="a">
                <li>The names and contact details of the parties;</li>
                <li>A statement of the facts supporting the claim;</li>
                <li>The points at issue;</li>
                <li>The relief or remedy sought;</li>
                <li>The legal grounds or arguments supporting the claim.</li>
              </ol>
            </li>
            <li>
              A copy of any contract or other legal instrument out of or in
              relation to which the dispute arises and of the arbitration
              agreement shall be annexed to the statement of claim.{" "}
            </li>
            <li>
              The statement of claim should, as far as possible, be accompanied
              by all documents and other evidence relied upon by the claimant,
              or contain references to them.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(16)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Statement of <span className="text-primary">defence</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[16] ? "up" : "down"}`}
            onClick={() => openCollapse(16)}></i>
        </div>

        <Collapse in={open[16]}>
          <ol>
            <strong>Article 21</strong>{" "}
            <li>
              The respondent shall communicate its statement of defence in
              writing to the claimant and to each of the arbitrators within a
              period of time to be determined by the arbitral tribunal. The
              respondent may elect to treat its response to the notice of
              arbitration referred to in article 4 as a statement of defence,
              provided that the response to the notice of arbitration also
              complies with the requirements of paragraph 2 of this article.
            </li>
            <li>
              The statement of defence shall reply to the particulars (b) to (e)
              of the statement of claim (art. 20, para. 2). The statement of
              defence should, as far as possible, be accompanied by all
              documents and other evidence relied upon by the respondent, or
              contain references to them.
            </li>
            <li>
              In its statement of defence, or at a later stage in the arbitral
              proceedings if the arbitral tribunal decides that the delay was
              justified under the circumstances, the respondent may make a
              counterclaim or rely on a claim for the purpose of a set-off
              provided that the arbitral tribunal has jurisdiction over it.
            </li>
            <li>
              The provisions of article 20, paragraphs 2 to 4, shall apply to a
              counterclaim, a claim under article 4, paragraph 2 (f), and a
              claim relied on for the purpose of a set-off.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(17)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Amendments to the{" "}
            <span className="text-primary">claim or defence</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[17] ? "up" : "down"}`}
            onClick={() => openCollapse(17)}></i>
        </div>

        <Collapse in={open[17]}>
          <ol>
            <strong> Article 22 </strong>
            <br />
            During the course of the arbitral proceedings, a party may amend or
            supplement its claim or defence, including a counterclaim or a claim
            for the purpose of a set-off, unless the arbitral tribunal considers
            it inappropriate to allow such amendment or supplement having regard
            to the delay in making it or prejudice to other parties or any other
            circumstances. However, a claim or defence, including a counterclaim
            or a claim for the purpose of a set-off, may not be amended or
            supplemented in such a manner that the amended or supplemented claim
            or defence falls outside the jurisdiction of the arbitral tribunal.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(18)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Pleas as to the jurisdiction of{" "}
            <span className="text-primary">the arbitral tribunal</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[18] ? "up" : "down"}`}
            onClick={() => openCollapse(18)}></i>
        </div>

        <Collapse in={open[18]}>
          <ol>
            <strong>Article 23</strong>{" "}
            <li>
              The arbitral tribunal shall have the power to rule on its own
              jurisdiction, including any objections with respect to the
              existence or validity of the arbitration agreement. For that
              purpose, an arbitration clause that forms part of a contract shall
              be treated as an agreement independent of the other terms of the
              contract. A decision by the arbitral tribunal that the contract is
              null shall not entail automatically the invalidity of the
              arbitration clause.
            </li>
            <li>
              A plea that the arbitral tribunal does not have jurisdiction shall
              be raised no later than in the statement of defence or, with
              respect to a counterclaim or a claim for the purpose of a set-off,
              in the reply to the counterclaim or to the claim for the purpose
              of a set-off. A party is not precluded from raising such a plea by
              the fact that it has appointed, or participated in the appointment
              of, an arbitrator. A plea that the arbitral tribunal is exceeding
              the scope of its authority shall be raised as soon as the matter
              alleged to be beyond the scope of its authority is raised during
              the arbitral proceedings. The arbitral tribunal may, in either
              case, admit a later plea if it considers the delay justified.
            </li>
            <li>
              The arbitral tribunal may rule on a plea referred to in paragraph
              2 either as a preliminary question or in an award on the merits.
              The arbitral tribunal may continue the arbitral proceedings and
              make an award, notwithstanding any pending challenge to its
              jurisdiction before a court.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(19)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Further written <span className="text-primary">statements</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[19] ? "up" : "down"}`}
            onClick={() => openCollapse(19)}></i>
        </div>

        <Collapse in={open[19]}>
          <ol>
            <strong>Article 24</strong> <br />
            The arbitral tribunal shall decide which further written statements,
            in addition to the statement of claim and the statement of defence,
            shall be required from the parties or may be presented by them and
            shall fix the periods of time for communicating such statements.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(20)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Periods of <span className="text-primary">time</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[20] ? "up" : "down"}`}
            onClick={() => openCollapse(20)}></i>
        </div>

        <Collapse in={open[20]}>
          <ol>
            <strong>Article 25</strong> <br />
            The periods of time fixed by the arbitral tribunal for the
            communication of written statements (including the statement of
            claim and statement of defence) should not exceed 45 days. However,
            the arbitral tribunal may extend the time limits if it concludes
            that an extension is justified.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(21)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Interim <span className="text-primary">measures</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[21] ? "up" : "down"}`}
            onClick={() => openCollapse(21)}></i>
        </div>

        <Collapse in={open[21]}>
          <ol>
            <strong>Article 26</strong>{" "}
            <li>
              The arbitral tribunal may, at the request of a party, grant
              interim measures.
            </li>
            <li>
              An interim measure is any temporary measure by which, at any time
              prior to the issuance of the award by which the dispute is finally
              decided, the arbitral tribunal orders a party, for example and
              without limitation, to:
              <ol type="a">
                <li>
                  Maintain or restore the status quo pending determination of
                  the dispute;{" "}
                </li>
                <li>
                  Take action that would prevent, or refrain from taking action
                  that is likely to cause, (i) current or imminent harm or (ii)
                  prejudice to the arbitral process itself;
                </li>
                <li>
                  Provide a means of preserving assets out of which a subsequent
                  award may be satisfied; or
                </li>
                <li>
                  Preserve evidence that may be relevant and material to the
                  resolution of the dispute.
                </li>
              </ol>
            </li>
            <li>
              The party requesting an interim measure under paragraphs 2 (a) to
              (c) shall satisfy the arbitral tribunal that:
              <ol type="a">
                <li>
                  Harm not adequately reparable by an award of damages is likely
                  to result if the measure is not ordered, and such harm
                  substantially outweighs the harm that is likely to result to
                  the party against whom the measure is directed if the measure
                  is granted; and
                </li>
                <li>
                  There is a reasonable possibility that the requesting party
                  will succeed on the merits of the claim. The determination on
                  this possibility shall not affect the discretion of the
                  arbitral tribunal in making any subsequent determination.{" "}
                </li>
              </ol>
            </li>
            <li>
              With regard to a request for an interim measure under paragraph 2
              (d), the requirements in paragraphs 3 (a) and (b) shall apply only
              to the extent the arbitral tribunal considers appropriate.
            </li>
            <li>
              The arbitral tribunal may modify, suspend or terminate an interim
              measure it has granted, upon application of any party or, in
              exceptional circumstances and upon prior notice to the parties, on
              the arbitral tribunal’s own initiative.
            </li>
            <li>
              The arbitral tribunal may require the party requesting an interim
              measure to provide appropriate security in connection with the
              measure.
            </li>
            <li>
              The arbitral tribunal may require any party promptly to disclose
              any material change in the circumstances on the basis of which the
              interim measure was requested or granted.
            </li>
            <li>
              The party requesting an interim measure may be liable for any
              costs and damages caused by the measure to any party if the
              arbitral tribunal later determines that, in the circumstances then
              prevailing, the measure should not have been granted. The arbitral
              tribunal may award such costs and damages at any point during the
              proceedings.{" "}
            </li>
            <li>
              A request for interim measures addressed by any party to a
              judicial authority shall not be deemed incompatible with the
              agreement to arbitrate, or as a waiver of that agreement.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(22)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Evidence <span className="text-primary"></span>
          </h3>
          <i
            className={`bi bi-chevron-${open[22] ? "up" : "down"}`}
            onClick={() => openCollapse(22)}></i>
        </div>

        <Collapse in={open[22]}>
          <ol>
            <strong>Article 27</strong>
            <li>
              Each party shall have the burden of proving the facts relied on to
              support its claim or defence.
            </li>
            <li>
              Witnesses, including expert witnesses, who are presented by the
              parties to testify to the arbitral tribunal on any issue of fact
              or expertise may be any individual, notwithstanding that the
              individual is a party to the arbitration or in any way related to
              a party. Unless otherwise directed by the arbitral tribunal,
              statements by witnesses, including expert witnesses, may be
              presented in writing and signed by them.
              <li>
                At any time during the arbitral proceedings the arbitral
                tribunal may require the parties to produce documents, exhibits
                or other evidence within such a period of time as the arbitral
                tribunal shall determine.{" "}
              </li>
              <li>
                The arbitral tribunal shall determine the admissibility,
                relevance, materiality and weight of the evidence offered.
              </li>
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(23)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Hearings <span className="text-primary"></span>
          </h3>
          <i
            className={`bi bi-chevron-${open[23] ? "up" : "down"}`}
            onClick={() => openCollapse(23)}></i>
        </div>

        <Collapse in={open[23]}>
          <ol>
            <strong>Article 28</strong>{" "}
            <li>
              In the event of an oral hearing, the arbitral tribunal shall give
              the parties adequate advance notice of the date, time and place
              thereof.
            </li>
            <li>
              Witnesses, including expert witnesses, may be heard under the
              conditions and examined in the manner set by the arbitral
              tribunal.
            </li>
            <li>
              Hearings shall be held in camera unless the parties agree
              otherwise. The arbitral tribunal may require the retirement of any
              witness or witnesses, including expert witnesses, during the
              testimony of such other witnesses, except that a witness,
              including an expert witness, who is a party to the arbitration
              shall not, in principle, be asked to retire.
            </li>
            <li>
              The arbitral tribunal may direct that witnesses, including expert
              witnesses, be examined through means of telecommunication that do
              not require their physical presence at the hearing (such as
              videoconference).
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(24)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Experts appointed by{" "}
            <span className="text-primary">the arbitral tribunal</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[24] ? "up" : "down"}`}
            onClick={() => openCollapse(24)}></i>
        </div>

        <Collapse in={open[24]}>
          <ol>
            <strong>Article 29</strong>{" "}
            <li>
              After consultation with the parties, the arbitral tribunal may
              appoint one or more independent experts to report to it, in
              writing, on specific issues to be determined by the arbitral
              tribunal. A copy of the expert’s terms of reference, established
              by the arbitral tribunal, shall be communicated to the parties.
            </li>
            <li>
              The expert shall, in principle before accepting appointment,
              submit to the arbitral tribunal and to the parties a description
              of his or her qualifications and a statement of his or her
              impartiality and independence. Within the time ordered by the
              arbitral tribunal, the parties shall inform the arbitral tribunal
              whether they have any objections as to the expert’s
              qualifications, impartiality or independence. The arbitral
              tribunal shall decide promptly whether to accept any such
              objections. After an expert’s appointment, a party may object to
              the expert’s qualifications, impartiality or independence only if
              the objection is for reasons of which the party becomes aware
              after the appointment has been made. The arbitral tribunal shall
              decide promptly what, if any, action to take.
            </li>
            <li>
              The parties shall give the expert any relevant information or
              produce for his or her inspection any relevant documents or goods
              that he or she may require of them. Any dispute between a party
              and such expert as to the relevance of the required information or
              production shall be referred to the arbitral tribunal for
              decision.
            </li>
            <li>
              Upon receipt of the expert’s report, the arbitral tribunal shall
              communicate a copy of the report to the parties, which shall be
              given the opportunity to express, in writing, their opinion on the
              report. A party shall be entitled to examine any document on which
              the expert has relied in his or her report.
            </li>
            <li>
              At the request of any party, the expert, after delivery of the
              report, may be heard at a hearing where the parties shall have the
              opportunity to be present and to interrogate the expert. At this
              hearing, any party may present expert witnesses in order to
              testify on the points at issue. The provisions of article 28 shall
              be applicable to such proceedings.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(25)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Default <span className="text-primary"></span>
          </h3>
          <i
            className={`bi bi-chevron-${open[25] ? "up" : "down"}`}
            onClick={() => openCollapse(25)}></i>
        </div>

        <Collapse in={open[25]}>
          <ol>
            <strong>Article 30</strong>{" "}
            <li>
              If, within the period of time fixed by these Rules or the arbitral
              tribunal, without showing sufficient cause:
              <ol type="a">
                <li>
                  The claimant has failed to communicate its statement of claim,
                  the arbitral tribunal shall issue an order for the termination
                  of the arbitral proceedings, unless there are remaining
                  matters that may need to be decided and the arbitral tribunal
                  considers it appropriate to do so;
                </li>
                <li>
                  The respondent has failed to communicate its response to the
                  notice of arbitration or its statement of defence, the
                  arbitral tribunal shall order that the proceedings continue,
                  without treating such failure in itself as an admission of the
                  claimant’s allegations; the provisions of this subparagraph
                  also apply to a claimant’s failure to submit a defence to a
                  counterclaim or to a claim for the purpose of a set-off.
                </li>
              </ol>
            </li>
            <li>
              If a party, duly notified under these Rules, fails to appear at a
              hearing, without showing sufficient cause for such failure, the
              arbitral tribunal may proceed with the arbitration.
            </li>
            <li>
              If a party, duly invited by the arbitral tribunal to produce
              documents, exhibits or other evidence, fails to do so within the
              established period of time, without showing sufficient cause for
              such failure, the arbitral tribunal may make the award on the
              evidence before it.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(26)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Closure of <span className="text-primary">hearings</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[26] ? "up" : "down"}`}
            onClick={() => openCollapse(26)}></i>
        </div>

        <Collapse in={open[26]}>
          <ol>
            <strong>Article 31</strong>{" "}
            <li>
              The arbitral tribunal may inquire of the parties if they have any
              further proof to offer or witnesses to be heard or submissions to
              make and, if there are none, it may declare the hearings closed.
            </li>
            <li>
              The arbitral tribunal may, if it considers it necessary owing to
              exceptional circumstances, decide, on its own initiative or upon
              application of a party, to reopen the hearings at any time before
              the award is made.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(27)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Waiver of right to <span className="text-primary">object</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[27] ? "up" : "down"}`}
            onClick={() => openCollapse(27)}></i>
        </div>

        <Collapse in={open[27]}>
          <ol>
            <strong>Article 32</strong> <br />A failure by any party to object
            promptly to any non-compliance with these Rules or with any
            requirement of the arbitration agreement shall be deemed to be a
            waiver of the right of such party to make such an objection, unless
            such party can show that, under the circumstances, its failure to
            object was justified.
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(28)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Decisions <span className="text-primary"></span>
          </h3>
          <i
            className={`bi bi-chevron-${open[28] ? "up" : "down"}`}
            onClick={() => openCollapse(28)}></i>
        </div>

        <Collapse in={open[28]}>
          <ol>
            <strong>Article 33</strong>{" "}
            <li>
              When there is more than one arbitrator, any award or other
              decision of the arbitral tribunal shall be made by a majority of
              the arbitrators.
            </li>
            <li>
              In the case of questions of procedure, when there is no majority
              or when the arbitral tribunal so authorizes, the presiding
              arbitrator may decide alone, subject to revision, if any, by the
              arbitral tribunal.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(29)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Form and effect of <span className="text-primary">the award</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[29] ? "up" : "down"}`}
            onClick={() => openCollapse(29)}></i>
        </div>

        <Collapse in={open[29]}>
          <ol>
            <strong>Article 34</strong>
            <li>
              The arbitral tribunal may make separate awards on different issues
              at different times.
            </li>
            <li>
              All awards shall be made in writing and shall be final and binding
              on the parties. The parties shall carry out all awards without
              delay.
            </li>
            <li>
              The arbitral tribunal shall state the reasons upon which the award
              is based, unless the parties have agreed that no reasons are to be
              given.
            </li>
            <li>
              An award shall be signed by the arbitrators and it shall contain
              the date on which the award was made and indicate the place of
              arbitration. Where there is more than one arbitrator and any of
              them fails to sign, the award shall state the reason for the
              absence of the signature.{" "}
            </li>
            <li>
              An award may be made public with the consent of all parties or
              where and to the extent disclosure is required of a party by legal
              duty, to protect or pursue a legal right or in relation to legal
              proceedings before a court or other competent authority.{" "}
            </li>
            <li>
              Copies of the award signed by the arbitrators shall be
              communicated to the parties by the arbitral tribunal.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(30)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Applicable law,{" "}
            <span className="text-primary">amiable compositeur</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[30] ? "up" : "down"}`}
            onClick={() => openCollapse(30)}></i>
        </div>

        <Collapse in={open[30]}>
          <ol>
            <strong>Article 35</strong>{" "}
            <li>
              The arbitral tribunal shall apply the rules of law designated by
              the parties as applicable to the substance of the dispute. Failing
              such designation by the parties, the arbitral tribunal shall apply
              the law which it determines to be appropriate.
            </li>
            <li>
              The arbitral tribunal shall decide as amiable compositeur or ex
              aequo et bono only if the parties have expressly authorized the
              arbitral tribunal to do so.
            </li>
            <li>
              In all cases, the arbitral tribunal shall decide in accordance
              with the terms of the contract, if any, and shall take into
              account any usage of trade applicable to the transaction.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(31)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Settlement or other grounds{" "}
            <span className="text-primary"> for termination</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[31] ? "up" : "down"}`}
            onClick={() => openCollapse(31)}></i>
        </div>

        <Collapse in={open[31]}>
          <ol>
            <strong>Article 36</strong>{" "}
            <li>
              If, before the award is made, the parties agree on a settlement of
              the dispute, the arbitral tribunal shall either issue an order for
              the termination of the arbitral proceedings or, if requested by
              the parties and accepted by the arbitral tribunal, record the
              settlement in the form of an arbitral award on agreed terms. The
              arbitral tribunal is not obliged to give reasons for such an
              award.
            </li>
            <li>
              If, before the award is made, the continuation of the arbitral
              proceedings becomes unnecessary or impossible for any reason not
              mentioned in paragraph 1, the arbitral tribunal shall inform the
              parties of its intention to issue an order for the termination of
              the proceedings. The arbitral tribunal shall have the power to
              issue such an order unless there are remaining matters that may
              need to be decided and the arbitral tribunal considers it
              appropriate to do so.
            </li>
            <li>
              Copies of the order for termination of the arbitral proceedings or
              of the arbitral award on agreed terms, signed by the arbitrators,
              shall be communicated by the arbitral tribunal to the parties.
              Where an arbitral award on agreed terms is made, the provisions of
              article 34, paragraphs 2, 4 and 5, shall apply
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(32)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Interpretation of <span className="text-primary">the award</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[32] ? "up" : "down"}`}
            onClick={() => openCollapse(32)}></i>
        </div>

        <Collapse in={open[32]}>
          <ol>
            <strong>Article 37</strong>{" "}
            <li>
              Within 30 days after the receipt of the award, a party, with
              notice to the other parties, may request that the arbitral
              tribunal give an interpretation of the award.
            </li>
            <li>
              The interpretation shall be given in writing within 45 days after
              the receipt of the request. The interpretation shall form part of
              the award and the provisions of article 34, paragraphs 2 to 6,
              shall apply
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(33)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Correction of <span className="text-primary">the award</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[33] ? "up" : "down"}`}
            onClick={() => openCollapse(33)}></i>
        </div>

        <Collapse in={open[33]}>
          <ol>
            <strong>Article 38</strong>{" "}
            <li>
              Within 30 days after the receipt of the award, a party, with
              notice to the other parties, may request the arbitral tribunal to
              correct in the award any error in computation, any clerical or
              typographical error, or any error or omission of a similar nature.
              If the arbitral tribunal considers that the request is justified,
              it shall make the correction within 45 days of receipt of the
              request.
            </li>
            <li>
              The arbitral tribunal may within 30 days after the communication
              of the award make such corrections on its own initiative.
            </li>
            <li>
              Such corrections shall be in writing and shall form part of the
              award. The provisions of article 34, paragraphs 2 to 6, shall
              apply.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(34)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Default <span className="text-primary"></span>
          </h3>
          <i
            className={`bi bi-chevron-${open[34] ? "up" : "down"}`}
            onClick={() => openCollapse(34)}></i>
        </div>

        <Collapse in={open[34]}>
          <ol>
            <strong>Article 39</strong>{" "}
            <li>
              Within 30 days after the receipt of the termination order or the
              award, a party, with notice to the other parties, may request the
              arbitral tribunal to make an award or an additional award as to
              claims presented in the arbitral proceedings but not decided by
              the arbitral tribunal.
            </li>
            <li>
              If the arbitral tribunal considers the request for an award or
              additional award to be justified, it shall render or complete its
              award within 60 days after the receipt of the request. The
              arbitral tribunal may extend, if necessary, the period of time
              within which it shall make the award.
            </li>
            <li>
              When such an award or additional award is made, the provisions of
              article 34, paragraphs 2 to 6, shall apply.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(35)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Definition of <span className="text-primary">costs</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[35] ? "up" : "down"}`}
            onClick={() => openCollapse(35)}></i>
        </div>

        <Collapse in={open[35]}>
          <ol>
            <strong>Article 40</strong>{" "}
            <li>
              The arbitral tribunal shall fix the costs of arbitration in the
              final award and, if it deems appropriate, in another decision.
            </li>
            <li>
              The term “costs” includes only:
              <ol type="a">
                <li>
                  The fees of the arbitral tribunal to be stated separately as
                  to each arbitrator and to be fixed by the tribunal itself in
                  accordance with article 41;
                </li>
                <li>
                  The reasonable travel and other expenses incurred by the
                  arbitrators;
                </li>
                <li>
                  {" "}
                  The reasonable costs of expert advice and of other assistance
                  required by the arbitral tribunal;
                </li>
                <li>
                  The reasonable travel and other expenses of witnesses to the
                  extent such expenses are approved by the arbitral tribunal;
                </li>
                <li>
                  The legal and other costs incurred by the parties in relation
                  to the arbitration to the extent that the arbitral tribunal
                  determines that the amount of such costs is reasonable;
                </li>
                <li>
                  Any fees and expenses of the appointing authority as well as
                  the fees and expenses of the Secretary-General of the PCA.{" "}
                </li>
              </ol>
            </li>
            <li>
              In relation to interpretation, correction or completion of any
              award under articles 37 to 39, the arbitral tribunal may charge
              the costs referred to in paragraphs 2 (b) to (f), but no
              additional fees.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(36)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Fees and expenses of{" "}
            <span className="text-primary">arbitrators</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[36] ? "up" : "down"}`}
            onClick={() => openCollapse(36)}></i>
        </div>

        <Collapse in={open[36]}>
          <ol>
            <strong>Article 41</strong>{" "}
            <li>
              The fees and expenses of the arbitrators shall be reasonable in
              amount, taking into account the amount in dispute, the complexity
              of the subject matter, the time spent by the arbitrators and any
              other relevant circumstances of the case.
            </li>
            <li>
              If there is an appointing authority and it applies or has stated
              that it will apply a schedule or particular method for determining
              the fees for arbitrators in international cases, the arbitral
              tribunal in fixing its fees shall take that schedule or method
              into account to the extent that it considers appropriate in the
              circumstances of the case.
            </li>
            <li>
              Promptly after its constitution, the arbitral tribunal shall
              inform the parties as to how it proposes to determine its fees and
              expenses, including any rates it intends to apply. Within 15 days
              of receiving that proposal, any party may refer the proposal to
              the appointing authority for review. If, within 45 days of receipt
              of such a referral, the appointing authority finds that the
              proposal of the arbitral tribunal is inconsistent with paragraph
              1, it shall make any necessary adjustments thereto, which shall be
              binding upon the arbitral tribunal.
            </li>
            <li>
              <ol type="a">
                <li>
                  When informing the parties of the arbitrators’ fees and
                  expenses that have been fixed pursuant to article 40,
                  paragraphs 2 (a) and (b), the arbitral tribunal shall also
                  explain the manner in which the corresponding amounts have
                  been calculated;
                </li>
                <li>
                  Within 15 days of receiving the arbitral tribunal’s
                  determination of fees and expenses, any party may refer for
                  review such determination to the appointing authority. If no
                  appointing authority has been agreed upon or designated, or if
                  the appointing authority fails to act within the time
                  specified in these Rules, then the review shall be made by the
                  Secretary-General of the PCA;
                </li>
                <li>
                  If the appointing authority or the Secretary-General of the
                  PCA finds that the arbitral tribunal’s determination is
                  inconsistent with the arbitral tribunal’s proposal (and any
                  adjustment thereto) under paragraph 3 or is otherwise
                  manifestly excessive, it shall, within 45 days of receiving
                  such a referral, make any adjustments to the arbitral
                  tribunal’s determination that are necessary to satisfy the
                  criteria in paragraph 1. Any such adjustments shall be binding
                  upon the arbitral tribunal;
                </li>
                <li>
                  Any such adjustments shall either be included by the arbitral
                  tribunal in its award or, if the award has already been
                  issued, be implemented in a correction to the award, to which
                  the procedure of article 38, paragraph 3, shall apply.
                </li>
              </ol>
            </li>
            <li>
              Throughout the procedure under paragraphs 3 and 4, the arbitral
              tribunal shall proceed with the arbitration, in accordance with
              article 17, paragraph 1.
            </li>
            <li>
              A referral under paragraph 4 shall not affect any determination in
              the award other than the arbitral tribunal’s fees and expenses;
              nor shall it delay the recognition and enforcement of all parts of
              the award other than those relating to the determination of the
              arbitral tribunal’s fees and expenses.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(37)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Allocation of <span className="text-primary">costs</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[37] ? "up" : "down"}`}
            onClick={() => openCollapse(37)}></i>
        </div>

        <Collapse in={open[37]}>
          <ol>
            <strong>Article 42</strong>{" "}
            <li>
              The costs of the arbitration shall in principle be borne by the
              unsuccessful party or parties. However, the arbitral tribunal may
              apportion each of such costs between the parties if it determines
              that apportionment is reasonable, taking into account the
              circumstances of the case.
            </li>
            <li>
              The arbitral tribunal shall in the final award or, if it deems
              appropriate, in any other award, determine any amount that a party
              may have to pay to another party as a result of the decision on
              allocation of costs.
            </li>
          </ol>
        </Collapse>
      </div>
      <div
        className="card my-2 p-2 shadow border-0"
        onClick={() => openCollapse(38)}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            Deposit of <span className="text-primary">costs</span>
          </h3>
          <i
            className={`bi bi-chevron-${open[38] ? "up" : "down"}`}
            onClick={() => openCollapse(38)}></i>
        </div>

        <Collapse in={open[38]}>
          <ol>
            <strong>Article 43</strong>{" "}
            <li>
              The arbitral tribunal, on its establishment, may request the
              parties to deposit an equal amount as an advance for the costs
              referred to in article 40, paragraphs 2 (a) to (c).
            </li>
            <li>
              During the course of the arbitral proceedings the arbitral
              tribunal may request supplementary deposits from the parties.
            </li>
            <li>
              If an appointing authority has been agreed upon or designated, and
              when a party so requests and the appointing authority consents to
              perform the function, the arbitral tribunal shall fix the amounts
              of any deposits or supplementary deposits only after consultation
              with the appointing authority, which may make any comments to the
              arbitral tribunal that it deems appropriate concerning the amount
              of such deposits and supplementary deposits.
            </li>
            <li>
              If the required deposits are not paid in full within 30 days after
              the receipt of the request, the arbitral tribunal shall so inform
              the parties in order that one or more of them may make the
              required payment. If such payment is not made, the arbitral
              tribunal may order the suspension or termination of the arbitral
              proceedings.
            </li>
            <li>
              After a termination order or final award has been made, the
              arbitral tribunal shall render an accounting to the parties of the
              deposits received and return any unexpended balance to the
              parties.{" "}
            </li>
          </ol>
        </Collapse>
      </div>
    </>
  );
}
