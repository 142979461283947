import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from "../screens/Home/Home";
import About from "../screens/About/About";
import Articles from "../screens/Articles/Articles";
import Mediation from "../screens/Mediation/Mediation";
import Arbitration from "../screens/Arbitration/Arbitration";

import Footer from "./Footer";
import NavBar from "./NavBar";

import Register from "../screens/Register/Register";
import Iscdr from "../screens/Iscdr/Iscdr";
import Admin from "../screens/Admin/Admin";
import Dashboard from "../screens/Admin/Dashboard";

import { connect } from "react-redux";
import MemberModal from "../components/MemberModal/MemberModal";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    validCode: state.validCode,
    admin: state.admin,
    token: state.token,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Navigation = ({ path, admin, validCode, setPath, token }: any) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setPath(window.location.pathname);
    if (token === null && window.location.pathname === "/admin/dashboard") {
      navigate("/admin");
    }
    window.scrollTo(0, 0);
  }, [token]);

  return (
    <>
      {path !== "/" && path !== "/admin/dashboard" && path !== "/admin" && (
        <NavBar />
      )}
      <Routes>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/admin/dashboard" element={<Dashboard />} />

        <Route path="/" element={<Register />} />
        {validCode && (
          <>
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/mediation" element={<Mediation />} />
            <Route path="/arbitration" element={<Arbitration />} />
            {/* <Route path="/article" element={<Article />} /> */}
            <Route path="/iscdr" element={<Iscdr />} />
          </>
        )}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {path !== "/admin" && path !== "/admin/dashboard" && (
        <Footer path={path} />
      )}{" "}
      <MemberModal />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
