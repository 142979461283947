import React from "react";

export default function Articles() {
  return (
    <>
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: "url('/images/hero_1.jpg')" }}
        data-aos="fade"
        data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-8 text-center"
              data-aos="fade-up"
              data-aos-delay="400">
              <h1 className="mb-4">Articles</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4  col-sm-6 col-12 mb-3">
              <div className="card border-0 shadow">
                <img src="/images/img_sq.jpg" className="card-img-top" alt="" />
                <div className="card-body text-center">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text mb-0">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptates, quas?
                  </p>
                  <a href="#" className="btn btn-primary">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-3">
              <div className="card border-0 shadow">
                <img src="/images/img_sq.jpg" className="card-img-top" alt="" />
                <div className="card-body text-center">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text mb-0">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptates, quas?
                  </p>
                  <a href="#" className="btn btn-primary">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12 mb-3">
              <div className="card border-0 shadow">
                <img src="/images/img_sq.jpg" className="card-img-top" alt="" />
                <div className="card-body text-center">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text mb-0">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptates, quas?
                  </p>
                  <button className="btn btn-primary">Read More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
