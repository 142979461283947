import React from "react";
import { Outlet } from "react-router";

import IntroductoryRules from "../../components/IntroductoryRules/IntroductoryRules";
import ArbitralTribunal from "../../components/ArbitralTribunal/ArbitralTribunal";
import ArbitralProceedings from "../../components/ArbitralProceedings/ArbitralProceedings";
import TheAward from "../../components/TheAward/TheAward";

export default function Arbitration() {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div
        className="site-blocks-cover inner-page-cover overlay"
        style={{ backgroundImage: "url('/assets/banners/2.jpg')" }}
        data-aos="fade"
        data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-8 text-center"
              data-aos="fade-up"
              data-aos-delay="400">
              <h1 className="mb-4">Arbitration</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 ">
        <div className="container pt-4">
          <div className="row no-gutters align-items-stretch align-items-center">
            <div className="col-lg-3">
              <div
                className="service-1 first h-100 cursor-pointer "
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}
                onClick={() => {
                  scrollTo("IntroductoryRules");
                }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-balance"></span>
                  </span>
                  <h2>Introductory Rules</h2>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="service-1 h-100 cursor-pointer "
                style={{ backgroundImage: "url('/images/img_2.jpg')" }}
                onClick={() => {
                  scrollTo("ArbitralTribunal");
                }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-law"></span>
                  </span>
                  <h2>Composition of the Arbitral Tribunal</h2>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="service-1 h-100 cursor-pointer "
                style={{ backgroundImage: "url('/images/img_3.jpg')" }}
                onClick={() => {
                  scrollTo("ArbitralProceedings");
                }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-courthouse"></span>
                  </span>
                  <h2>Arbitral Proceedings</h2>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="service-1 h-100 cursor-pointer "
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}
                onClick={() => {
                  scrollTo("TheAward");
                }}>
                <div className="service-1-contents ">
                  <span className="wrap-icon">
                    <span className="bi bi-award"></span>
                  </span>
                  <h2>The Award</h2>
                  <p></p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div
                className="service-1 first h-100"
                style={{ backgroundImage: "url('/images/img_1.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-balance"></span>
                  </span>
                  <h2>Business Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_2.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-law"></span>
                  </span>
                  <h2>Criminal Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-1 h-100"
                style={{ backgroundImage: "url('/images/img_3.jpg')" }}>
                <div className="service-1-contents">
                  <span className="wrap-icon">
                    <span className="flaticon-courthouse"></span>
                  </span>
                  <h2>Tax Law</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Exercitationem vel odit tempora itaque, repellendus
                    distinctio harum totam. Rerum.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="py-5" id="IntroductoryRules">
          <IntroductoryRules />
        </div>
        <div className="py-5" id="ArbitralTribunal">
          <ArbitralTribunal />
        </div>
        <div className="py-5" id="ArbitralProceedings">
          <ArbitralProceedings />
        </div>
        <div className="py-5" id="TheAward">
          <TheAward />
        </div>
        <p className="text-justify">
          <br />
          <i>
            Employees and workers of the International Center for Disputes
            Resolution ICDR, holders of cards and certificates, representatives
            of countries under protocols or others in all countries in the event
            of any dispute arising, it is resolved by the International Center
            for Disputes Resolution ICDR through mediation or arbitration in
            accordance with justice and fairness.
          </i>
          <br />
          <strong>Modal Clause</strong>
          <br />

          <i>
            Under this contract, the parties have agreed on any dispute arising
            in connection with this contract first related to it, whether
            related to its implementation, non-performance, validity,
            invalidity, rescission or termination, and it shall be finally
            resolved in accordance with the arbitration rules set by the
            UNCITRAL Commission year 2013 or ad hoc, provided that the
            arbitrator The power to issue temporary or precautionary decisions
            or orders regarding a dispute presented to it by an arbitrator or
            several arbitrators.
          </i>
          <br />
          <i>
            Arbiration rulings: are final, enforceable and not subject to appeal
            in any way of appeal and appeal, absolute and finding on its paries.
            The arbitrators are also appointed by the International Supreme
            Court for Dispute Resolution London ISCDR - from its arbitrators
            list it has.
          </i>
        </p>
      </div>
    </>
  );
}
